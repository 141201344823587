import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Button, Spinner } from 'react-bootstrap'
import configuredAxios from '../configured-axios'
import { TRUCKING_LIST_URL, TRUCKS_URL } from './api_urls'
import { toast } from 'react-toastify'
import DailyRunForm from './daily_run/DailyRunForm'
import { DateTime } from 'luxon'
import ReactToPrint from 'react-to-print'

const TruckingListPage = () => {
  const [trucks, setTrucks] = useState([])
  const [truckingList, setTruckingList] = useState([])
  const [dailyRunFormErrors, setDailyRunFormErrors] = useState({})
  const [reportCreated, setReportCreated] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  let printableComponentRef = useRef()

  const currentDateString = DateTime.now().toISODate()
  const emptyRunFormData = {
    date: currentDateString,
    truck: null
  }
  const [runFormData, setRunFormData] = useState(emptyRunFormData)

  const maxPageContentHeightPx = 1080
  const [runHeights, setRunHeights] = useState([])
  const [resetHeights, setResetHeights] = useState(false)
  const runElementRefs = useRef([])
  const runElements = []

  function validateRunForm(run) {
    const errors = {}

    if (!run.date) errors.date = 'Date is required'
    if (!run.truckId) errors.truck = 'Truck is required'

    return errors
  }

  // Get list of trucks for the truck dropdown
  useEffect(() => {
    const searchParams = new URLSearchParams({
      status: 'true'
    })
    configuredAxios.get(TRUCKS_URL, { params: searchParams }).then((res) => {
      setTrucks(res.data)
    }).catch((err) => {
      toast.error('Could not retrieve Trucks')
      setTrucks([])
      console.log(err)
    })
  }, [])

  function handleDailyRunChange(dailyRun) {
    setRunFormData(dailyRun)
  }

  // Fires when "Create Report" button is pressed
  // Grabs report data from the API and prepares state for page renders
  function handleCreateReport() {
    setRunHeights([])
    const dailyRunData = {
      date: runFormData.date,
      truckId: runFormData.truck?.value
    }
    const errors = validateRunForm(dailyRunData)
    if (Object.keys(errors).length === 0) {
      const searchParams = new URLSearchParams({
        offset: 0,
        limit: 15,
        truckId: dailyRunData.truckId,
        runDate: dailyRunData.date
      })
      setShowLoader(true)
      configuredAxios.get(TRUCKING_LIST_URL, { params: searchParams }).then((res) => {
        setTruckingList(res.data)
        setDailyRunFormErrors({})
        runElementRefs.current = []
        setResetHeights(true)
        setReportCreated(true)
        setShowLoader(false)
      }).catch((err) => {
        toast.error('Could not retrieve Trucking List')
        setTruckingList([])
        console.log(err)
        setShowLoader(false)
      })
    } else {
      setDailyRunFormErrors(errors)
    }
  }

  // After turning the report data into HTML elements,
  // this calculates the elements' heights in order to
  // determine which elements should go on which pages
  useEffect(() => {
    if (resetHeights) {
      setRunHeights(runElementRefs.current.map(el => el?.clientHeight))
      setResetHeights(false)
    }
  }, [resetHeights])

  // Resets data if form fields are changed
  const selectedTruckId = runFormData.truck?.value
  const selectedDate = runFormData.date
  useEffect(() => {
    setTruckingList([])
    setReportCreated(false)
  }, [selectedTruckId, selectedDate])

  // Build initial "run elements", HTML elements each representing a run.
  // The run elements have to be created and rendered before we can divide
  // them into pages so that we can look at the height of each run element
  // and figure out where there needs to be page breaks.
  for (let i = 0; i < truckingList.length; i++) {
    const emptyRun = {
      customer_name: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      description: '',
      contact_name: '',
      phone: ''
    }
    let run = emptyRun
    if (i < truckingList.length) {
      run = truckingList[i]
    }

    let locationText = ''
    if (run.address) {
      locationText = `${run.address}, ${run.city}, ${run.state} ${run.zip}`
    }

    runElements.push(
      <Row className='no-gutters border border-dark font-weight-bold mb-3' key={i} ref={el => { runElementRefs.current[i] = el }}>
        <Col xs={1}>
          {i + 1}.&#41;<br />
          <span className='border border-left-0 border-dark p-2 m-0' style={{ width: '30px', height: '20px', display: 'inline-block', borderWidth: '2px' }} />
          <div style={{ height: '20px' }} />
        </Col>
        <Col>
          <div>{run.customer_name}</div>
          <div>{locationText}</div>
          <div className='text-success'>{run.contact_name} {run.phone}</div>
        </Col>
        <Col className='text-info'>{run.description}</Col>
      </Row>
    )
  }

  // This function takes data and a number of run elements
  // and formats them as the final page output
  function createPage(pageNumber, truckName, runDate, pageElements) {
    return (
      <Container key={`page-${pageNumber}`} className='p-0 m-0'>
        <Row className='align-items-md-center mb-2'>
          <Col>
            <h3 className='m-0'>Trucking List</h3>
          </Col>
          <Col>
            <span className='font-weight-bold float-right'>DATE:</span>
          </Col>
          <Col>
            <h5 className='float-right'>{DateTime.fromFormat(runDate, 'yyyy-MM-dd').toLocaleString()}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5><span className='text-danger'>Truck: </span><span className='font-weight-normal'>{truckName}</span></h5>
          </Col>
        </Row>
        <Container className='p-0'>
          {pageElements}
        </Container>
        <p className='mt-1 mb-2' style={{ textAlign: 'right' }}>Page: {pageNumber}</p>
      </Container>
    )
  }

  // Loop over runs elements and move them each to pages, paying attention to their
  // heights. After building up a page, if adding another element to that page would
  // go over the page's maximum height, we start a new page.
  const pages = []
  let pageElementsHeight = 0
  let pageElements = []
  let j = 1
  let i = 0
  while (runElements.length > 0 && runHeights[0] > 0) {
    pageElementsHeight += runHeights[i]
    if (pageElementsHeight <= maxPageContentHeightPx) {
      pageElements.push(runElements.shift())
    } else {
      pages.push(createPage(j, truckingList[0]?.truck_name, truckingList[0]?.date, pageElements))
      j++
      pageElementsHeight = 0
      pageElements = [runElements.shift()]
    }
    i++
  }
  if (pageElements.length > 0) {
    pages.push(createPage(j, truckingList[0]?.truck_name, truckingList[0]?.date, pageElements))
    pageElementsHeight = 0
    pageElements = []
  }

  // Format the final page elements for display on the web page
  // (margins, minimum page height, border, etc.)
  const displayedPages = []
  for (const p of pages) {
    displayedPages.push(
      <>
        <Container style={{ minHeight: '1400px' }} className='border p-5 mb-0'>
          {p}
        </Container>
      </>
    )
  }

  // Format the final page elements for paper printing.
  // (no margins, minimal extra styles)
  const printedPages = []
  for (const p of pages) {
    printedPages.push(
      <>
        <div style={{ padding: '0.5in' }}>
          {p}
        </div>
        <div style={{ breakAfter: 'page' }} />
      </>
    )
  }

  // "Hide" the pages while rendering and page height calculations are happening
  // We need to use "visibility: hidden" instead of "display: none" so that
  // element heights are calculated correctly.
  let pageVisibility = 'hidden'
  if (displayedPages.length > 0) {
    pageVisibility = 'visible'
  }

  // If no runs are found for a given truck/date, show this message
  let noDataMessage = (<></>)
  if (reportCreated && displayedPages.length === 0 && !showLoader) {
    noDataMessage = (<p>No runs assigned for the selected date and truck. Please try again with different selections.</p>)
  }

  // Show this message on page load and when the form data is changed
  let createReportMessage = (<></>)
  if (!reportCreated && !showLoader) {
    createReportMessage = (<p>To get started, choose a date and truck and click "Create Report".</p>)
  }

  // Show a loading spinner when we are waiting on API data
  let loader = (<></>)
  if (showLoader) {
    loader = (
      <Row className='justify-content-md-center'>
        <Spinner animation='border' />
      </Row>
    )
  }

  return (
    <Container>
      <h2>Trucking List</h2>
      <Container>
        <Container>
          <Row>
            <Col md='auto'>
              <DailyRunForm
                truckList={trucks}
                onChange={(dailyRun) => { handleDailyRunChange(dailyRun) }}
                dailyRun={runFormData}
                errors={dailyRunFormErrors}
              />
            </Col>
            <Col>
              <Button onClick={() => handleCreateReport()} style={{ marginTop: '32px' }}>Create Report</Button>
            </Col>
          </Row>
        </Container>
        {loader}
        {noDataMessage}
        {createReportMessage}
        <div style={{ visibility: pageVisibility }}>
          <Container>
            <Row className='justify-content-md-end'>
              <ReactToPrint
                trigger={() => <Button>Print</Button>}
                content={() => printableComponentRef}
              />
            </Row>
          </Container>
          {displayedPages}
          {runElements}
        </div>
        <div style={{ display: 'none' }}>
          <div ref={(el) => (printableComponentRef = el)}>
            {printedPages}
          </div>
        </div>
      </Container>
    </Container>
  )
}

export default TruckingListPage
