import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { RUNS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

function EditNotesModal(props) {
  const initialNote = {
    description: props.note
  }

  const [note, setNote] = useState(initialNote)

  function handleChange({ target }) {
    setNote({
      ...note,
      [target.name]: target.value
    })
  }

  async function handleSubmit(evt) {
    evt.preventDefault()

    const postNote = async (l) => {
      Object.assign(l, {
        id: props.runId
      })
      try {
        await configuredAxios.put(`${RUNS_URL}/${props.runId}`, l)
        toast.success('Run Updated Successfully')
      } catch (error) {
        toast.error('Run could not be updated')
      }
    }
    postNote(note)
    props.handleClose()
    props.handleRefresh(true)
  }

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>Edit Note</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <Form.Control
              as='textarea'
              rows='3'
              name='description'
              value={note.description}
              onChange={handleChange}
            />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant='primary' type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditNotesModal
