import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Select from 'react-select'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button'

import { dmyString } from '../../helper'
import { RUNS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

function MoveRunForm(props) {
  const run = {
    dailyRunId: props.dailyRunId,
    pickUpId: props.pickUpId,
    dropOffId: props.dropOffId,
    locationId: props.locationId,
    description: props.description,
    id: props.id
  }

  const initialDailyRun = {
    value: props.dailyRunId
  }

  const dailyRuns = props.dailyRuns

  const [dailyRun, setDailyRun] = useState(initialDailyRun)
  const [dailyFiltered, setDailyFiltered] = useState([])
  const [refresh, setRefresh] = useState([])

  useEffect(() => {
    setDailyFiltered(
      dailyRuns.filter((obj) => obj.id !== run.dailyRunId)
    )
    setRefresh(false)
  }, [dailyRuns, refresh, run.dailyRunId])

  function handleChange(evt) {
    setDailyRun({
      value: evt.value
    })
  }

  function handleSubmit(evt) {
    evt.preventDefault()

    const updateRun = async (_run) => {
      try {
        await configuredAxios.put(`${RUNS_URL}/${_run.id}`, _run)
        props.handleRefresh(true)
        toast.success('Run moved successfully!')
      } catch (error) {
        toast.error('Run could not be moved.')
      }
    }
    updateRun({
      id: run.id,
      dailyRunId: dailyRun.value
    })
  }

  return (
    <Form onSubmit={handleSubmit} className='move-run-form'>
      <Form.Row>
        <Form.Label>New Truck # &amp; Date:</Form.Label>
      </Form.Row>
      <Form.Row>
        <Select
          options={dailyFiltered.map((c) => {
            return {
              value: c.id,
              label: `${c.truck.number}, ${dmyString(c.date)}`
            }
          })}
          onChange={handleChange}
          defaultValue={initialDailyRun}
          className='move-run-select'
          isDisabled={props.complete}
        />
      </Form.Row>
      {props.complete ? 'Run must be reactivated in order to change' : (
        <Button variant='primary' type='submit' disabled={props.complete}>
          Move Run
        </Button>
      )}
    </Form>
  )
}

export default MoveRunForm
