import React, { useState, useEffect } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import { toast } from 'react-toastify'

import { dmyString } from '../../helper'
import RunList from '../runs/RunList'
import {
  TOGGLE_RUN_COMPLETE_URL,
  REMOVE_RUN,
  REMOVE_RECURRING,
  TOGGLE_RUN_INCOMPLETE_URL,
  PUBLIC_DAILY_RUNS_URL
} from '../api_urls'
import configuredAxios from '../../configured-axios'

function runWithId(id) {
  return REMOVE_RUN + '/' + id
}

function DailyRunDetailHeader({ dailyRun, id }) {
  if (Object.keys(dailyRun).length === 0) {
    return <div>No Daily Run for id: {id}</div>
  } else {
    return (
      <Container>
        <div>Date:&nbsp;{dmyString(dailyRun.date)} </div>
        <div>Code:&nbsp;{dailyRun.code}</div>
        <div>Truck Number:&nbsp;{dailyRun.truck.number}</div>
        <div>
          Truck Description:&nbsp;{dailyRun.truck.description}
        </div>
      </Container>
    )
  }
}

function DailyRunDetail(props) {
  const [dailyRun, setDailyRun] = useState({})
  const [refresh, setRefresh] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    const URL = PUBLIC_DAILY_RUNS_URL + '/' + id
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(URL)
        setDailyRun(response.data)
      } catch (error) {
        toast.error('Could not retrieve daily runs')
        setDailyRun({})
      }
    }
    fetchData()
    setRefresh(false)
  }, [id, refresh])

  async function markComplete(id) {
    const complete = async () => {
      toast.info('Completing run, this could take a few moments...')
      try {
        await configuredAxios.put(TOGGLE_RUN_COMPLETE_URL, { id: id })
          .then(res => {
            toast.success('Run updated')
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          }).catch(err => {
            toast.error(err.response.data.message)
            setTimeout(() => {
              window.location.reload()
            }, 4000)
          })
      } catch (error) {
        toast.error('Run could not be updated')
      }
    }
    await complete()
  }

  async function markIncomplete(id) {
    const complete = async () => {
      toast.info('Activating run, one moment please...')
      try {
        await configuredAxios.put(TOGGLE_RUN_INCOMPLETE_URL, { id: id })
        toast.success('Run updated successfully!')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      } catch (error) {
        toast.error(error.response.data.message)
      }
    }
    await complete()
    setRefresh(true)
  }

  async function handleDestroyRun(id) {
    const putAlert = async (_id) => {
      try {
        await configuredAxios.delete(runWithId(_id))
        setRefresh(true)
        toast.success('Deleted Run: ', _id)
      } catch (error) {
        toast.error('Could not delete run')
      }
    }
    putAlert(id)
  }

  async function handleDestroySeries(id, dailyRunFlag) {
    const putAlert = async (_id, _dailyRunFlag) => {
      try {
        await configuredAxios
          .delete(
            REMOVE_RECURRING +
              '/' +
              _id +
              '/' +
              _dailyRunFlag +
              '/' +
              dailyRun.id
          )
          .then((result) => {
            if (result.data.redirect) {
              setRedirect(true)
            }
          })
        toast.success('Deleted Run Series: ', _id)
        setRefresh(true)
      } catch (error) {
        toast.error('Could not delete run series')
      }
    }
    putAlert(id, dailyRunFlag)
  }

  function changeRefresh() {
    const updated = !refresh
    setRefresh(updated)
  }

  if (redirect) {
    return <Redirect push to={{ pathname: '/run/' }} />
  } else {
    return (
      <Container>
        <h2>Daily Run</h2>
        <DailyRunDetailHeader dailyRun={dailyRun} id={id} />
        <RunList
          runs={dailyRun.runs}
          dailyRunId={dailyRun.id}
          handleRefresh={changeRefresh}
          markComplete={markComplete}
          markIncomplete={markIncomplete}
          authenticated={props.authenticated}
          handleDestroy={handleDestroyRun}
          handleDestroySeries={handleDestroySeries}
        />
      </Container>
    )
  }
}

export default DailyRunDetail
