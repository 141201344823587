import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TextInput from '../common/TextInput'
import PropTypes from 'prop-types'
import { shallowClone } from '../../helper'

function CustomerForm({ onSubmit = () => {}, onError = () => {}, onChange = () => {}, customer = { name: '', number: '' } }) {
  const [errors, setErrors] = useState({})

  function validateCustomer() {
    const formErrors = {}
    if (!customer.number) formErrors.number = 'Number is required'
    if (!customer.name) formErrors.name = 'Name is required!'

    return formErrors
  }

  function handleFormSubmit(evt) {
    evt.preventDefault()
    const formErrors = validateCustomer()
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      onError(formErrors)
    } else {
      onSubmit()
    }
  }

  function onChangeName(evt) {
    const newCustomer = shallowClone(customer)
    newCustomer.name = evt.target.value
    onChange(newCustomer)
  }

  function onChangeNumber(evt) {
    const newCustomer = shallowClone(customer)
    newCustomer.number = evt.target.value
    onChange(newCustomer)
  }

  return (
    <Form onSubmit={(evt) => { handleFormSubmit(evt) }}>
      <Form.Row>
        <TextInput
          label='Number'
          name='number'
          error={errors.number}
          value={customer.number}
          onChange={onChangeNumber}
        />
        <TextInput
          label='Name'
          name='name'
          error={errors.name}
          value={customer.name}
          onChange={onChangeName}
        />
      </Form.Row>
      <Button variant='primary' type='submit' className='float-right'>
        Submit
      </Button>
    </Form>
  )
}

CustomerForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onChange: PropTypes.func,
  customer: PropTypes.object
}

export default CustomerForm
