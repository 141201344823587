import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { SIMPLE_LOCATIONS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

// Needed to customize the input label too much to use the DataSelect.
// Would love to find a way not have this be a one-off component.
function LocationDataSelectInput(props) {
  const [collection, setCollection] = useState([])
  const [refresh, setRefresh] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(SIMPLE_LOCATIONS_URL)
        setCollection(
          response.data.map((c) => {
            const nickname = c.nickname ? ` (${c.nickname})` : ''
            const name_and_loc =
              c.customer.name + ': ' + c.address + nickname
            return { value: c.id, label: name_and_loc }
          })
        )
      } catch (error) {
        toast.error('Could not retrieve ' + props.label)
        setCollection([])
      }
    }
    fetchData()
    setRefresh(false)
  }, [refresh, props.label])

  return (
    <Form.Group as={Col} controlId={'formGrid' + props.name}>
      <Form.Label>{props.label}</Form.Label>
      <Select
        options={collection}
        defaultValue={{ value: props.value, label: props.label }}
        onChange={(res) => {
          props.handleChange(props.name, res.value)
        }}
      />
    </Form.Group>
  )
}

LocationDataSelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default LocationDataSelectInput
