import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import TextInput from '../common/TextInput'
import StateSelect from '../common/StateSelect'
import { LOCATIONS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

function EditAddressModal(props) {
  const initialLocation = {
    address: props.location.address,
    address2: props.location.address2,
    city: props.location.city,
    state: props.location.state,
    zip: props.location.zip,
    name: props.location.name,
    phone: props.location.phone,
    nickname: props.location.nickname
  }

  const [location, setLocation] = useState(initialLocation)
  const [errors, setErrors] = useState({})

  function handleChange({ target }) {
    setLocation({
      ...location,
      [target.name]: target.value
    })
  }

  function validForm() {
    const _errors = {}

    if (!location.address) _errors.address = 'Address is required'
    if (!location.city) _errors.city = 'City is required!'
    if (!location.zip) _errors.zip = 'Zip is required!'

    setErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  async function handleSubmit(evt) {
    evt.preventDefault()
    if (!validForm()) return

    const postLocation = async (l) => {
      Object.assign(l, { customerId: props.location.customerId, id: props.locationId })
      try {
        await configuredAxios.put(`${LOCATIONS_URL}/${props.locationId}`, l)
        toast.success('Location Updated Successfully')
      } catch (error) {
        toast.error('Location could not be updated')
      }
    }
    postLocation(location)
    props.handleClose()
    props.handleRefresh(true)
  }

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>Edit Address</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <TextInput
              label='Nickname'
              name='nickname'
              value={location.nickname}
              onChange={handleChange}
              error={errors.nickname}
            />
          </Form.Row>
          <Form.Row>
            <TextInput
              label='Address'
              name='address'
              value={location.address}
              onChange={handleChange}
              error={errors.address}
            />
          </Form.Row>

          <Form.Row>
            <TextInput
              label='City'
              name='city'
              value={location.city}
              onChange={handleChange}
              error={errors.city}
            />
            <StateSelect
              value={location.state || 'Ohio'}
              onChange={handleChange}
            />
            <TextInput
              label='Zip'
              name='zip'
              value={location.zip}
              onChange={handleChange}
              error={errors.zip}
            />
          </Form.Row>

          <Form.Row>
            <TextInput
              label='Name'
              name='name'
              value={location.name}
              onChange={handleChange}
              error={errors.name}
            />

            <TextInput
              label='Phone'
              name='phone'
              value={location.phone}
              onChange={handleChange}
              error={errors.phone}
            />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='seconday' onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant='primary' type='submit'>Save Changes</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditAddressModal
