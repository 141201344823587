export const AUTHENTICATE_URL = '/api-public/authenticate'
export const REFRESH_URL = '/api-public/refresh'
export const TRUCKS_URL = '/api/trucks'
export const SIMPLE_TRUCKS_URL = '/api/simple/trucks'
export const DAILY_RUNS_URL = '/api/daily_runs'
export const PUBLIC_DAILY_RUNS_URL = '/api-public/daily_runs'
export const SIMPLE_DAILY_RUNS_URL = '/api-public/simple/daily_runs'
export const SIMPLE_CURRENT_DAILY_RUNS_URL = '/api/simple/current/daily_runs'
export const SIMPLE_PREVIOUS_DAILY_RUNS_URL = '/api/simple/previous/daily_runs'
export const RUNS_URL = '/api/runs'
export const PUBLIC_RUNS_URL = '/api-public/runs'
export const ADD_TO_DAILY_RUNS = '/api-public/add_run_to_daily_run'
export const JOB_TYPES_URL = '/api/job_types'
export const CUSTOMERS_URL = '/api/customers'
export const CUSTOMERS_SEARCH_URL_2 = '/api/customers-search'
export const SIMPLE_CUSTOMERS_URL = '/api/simple/customers'
export const CONTAINERS_URL = '/api/container_boxes'
export const SIMPLE_CONTAINERS_URL = '/api-public/simple/container_boxes'
export const ALERTS_URL = '/api/alerts'
export const UNREAD_ALERTS_URL = '/api/unreadalerts'
export const REPAIRS_URL = '/api/repairs'
export const LOCATIONS_URL = '/api/locations'
export const SIMPLE_LOCATIONS_URL = '/api-public/simple/locations'
export const MOVE_CONTAINER_URL = '/api/move_container'
export const CONTAINER_REPORT_URL = '/api/container_report'
export const TOGGLE_RUN_COMPLETE_URL = '/api-public/toggle_run_complete/'
export const CONTACTS_URL = '/api/contacts'
export const DAILY_RUN_BY_CODE = '/api-public/find_by_code/'
export const REMOVE_RUN = '/api-public/remove_run_from_daily_run'
export const REMOVE_RECURRING = '/api-public/remove_run_series_from_daily_run'
export const CUSTOMERS_SEARCH_URL = '/api/search/customers'
export const IS_RECID_UNIQUE_URL = '/api-public/is_recid_unique'
export const IS_CODE_UNIQUE_URL = '/api/is_code_unique'
export const GET_NEWS_FEED_URL = '/api/get_news_feed'
export const TOGGLE_RUN_INCOMPLETE_URL = '/api-public/toggle_incomplete'
export const EMAIL_ALERT_RECIPIENTS_URL = '/api/email_alert_recipients'
export const TRUCKING_LIST_URL = '/api/trucking_list'
export const CONTAINERS_SEARCH_URL = '/api/search/containers'
export const RUN_PICTURE_LIST_URL = '/api-public/runs/pictures/'
export const RUN_PICTURE_UPLOAD_URL = '/api-public/runs/picture'
export const RUN_PICTURE_DELETE_URL = '/api/runs/picture'
