import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TextInput from './common/TextInput'
import configuredAxios from '../configured-axios'
import { AUTHENTICATE_URL } from './api_urls'
import PropTypes from 'prop-types'

const LoginPage = ({ setTokens, onSuccessfulLogin }) => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errors, setErrors] = React.useState({})

  const handleLogin = (evt) => {
    evt.preventDefault()
    if (!validForm()) return

    const params = new URLSearchParams()
    params.append('email', email)
    params.append('password', password)
    configuredAxios.post(AUTHENTICATE_URL, params).then((res) => {
      setTokens(res.data)
      onSuccessfulLogin()
    }).catch((_err) => {
      toast.error('User could not be Authenticated')
    })
  }

  const validForm = () => {
    const _errors = {}

    if (!email) _errors.email = 'Email is required'
    if (!password) _errors.password = 'Password is required!'

    setErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  return (
    <div className='login-page'>
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='login-form'>
        <Form onSubmit={handleLogin}>
          <Form.Row>
            <TextInput
              label='Email'
              name='email'
              value={email}
              onChange={(e) => { setEmail(e.target.value) }}
              error={errors.email}
            />
          </Form.Row>
          <Form.Row>
            <TextInput
              label='Password'
              name='password'
              value={password}
              onChange={(e) => { setPassword(e.target.value) }}
              error={errors.password}
              password
            />
          </Form.Row>
          <Form.Row>
            <Button variant='primary' type='submit' className='float-right'>
              Submit
            </Button>
          </Form.Row>
        </Form>
      </div>
    </div>)
}

LoginPage.propTypes = {
  setTokens: PropTypes.func.isRequired,
  onSuccessfulLogin: PropTypes.func
}

export default LoginPage
