import React from 'react'
import { ToastContainer } from 'react-toastify'

import DailyRunDetail from './DailyRunDetail'

function TruckDailyRunDetail() {
  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar />
      <DailyRunDetail />
    </>
  )
}

export default TruckDailyRunDetail
