import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { Plus } from "react-bootstrap-icons";

import { dmyString } from "../../helper";
import HistoryForm from "./HistoryForm";

function HistoryTableRow(props) {
  if (props.location) {
    return (
      <tr>
        <td>
          <b>{props.location.customer.name}:</b>
          &nbsp;&nbsp;
          <i>{props.location.address}</i>
        </td>
        <td>{dmyString(props.dropOff)}</td>
        <td>{dmyString(props.pickUp)}</td>
      </tr>
    );
  } else {
    return (
      <tr>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  }
}

function Rows(props) {
  if (props.histories && props.histories.length > 0) {
    return props.histories.map((history) => {
      return <HistoryTableRow {...history} key={history.id} />;
    });
  } else {
    return (
      <tr>
        <td colSpan="4"> No History Found</td>
      </tr>
    );
  }
}

function HistoryList(props) {
  const [showForm, setShowForm] = useState(false);

  function currentLocationId() {
    if (props.histories && props.histories.length > 0) {
      return props.histories[0].location.id.toString();
    } else {
      return "0";
    }
  }

  return (
    <Container>
      <h4>
        History&nbsp;
        <Plus onClick={() => setShowForm(!showForm)} />
      </h4>
      {showForm && (
        <HistoryForm
          containerBoxId={props.containerBoxId}
          currentLocationId={currentLocationId()}
          handleRefresh={props.handleRefresh}
          handleShowForm={setShowForm}
        />
      )}
      <Table bordered hover>
        <thead>
          <tr>
            <th>History Location</th>
            <th>Dropped Off</th>
            <th>Picked Up</th>
          </tr>
        </thead>
        <tbody>
          <Rows histories={props.histories} />
        </tbody>
      </Table>
    </Container>
  );
}

export default HistoryList;
