import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import ContainerBoxList from './container_box/ContainerBoxList'
import ContainerBoxForm from './container_box/ContainerBoxForm'
import { CONTAINERS_SEARCH_URL, CONTAINERS_URL } from './api_urls'
import configuredAxios from '../configured-axios'
import { Col, Form, Row } from 'react-bootstrap'
import Search from './common/Search'
import TablePaginator from './common/TablePaginator'
import TableSorter from './common/TableSorter'

function ContainerBoxPage() {
  const emptyContainerBox = {
    number: '',
    size: '',
    description: ''
  }
  const [containerBoxes, setContainerBoxes] = useState([])
  const [containerBox, setContainerBox] = useState(emptyContainerBox)
  const [errors, setErrors] = useState({})

  const [containerTableLimit, setContainerTableLimit] = useState(20)
  const [containerTableOffset, setContainerTableOffset] = useState(0)
  const [containerTableSortOption, setContainerTableSortOption] = useState('containerNumber')
  const [containerTableSortDirection, setContainerTableSortDirection] = useState(true)
  const [containerTotalCount, setContainerTotalCount] = useState(0)

  const [searchTerm, setSearchTerm] = useState('')
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState('')

  const [daysInactive, setDaysInactive] = useState('')
  const [submittedDaysInactive, setSubmittedDaysInactive] = useState('')

  const [createContainerLoading, setCreateContainerLoading] = useState(false)
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)

  const history = useHistory()

  function handleChange({ target }) {
    setContainerBox({
      ...containerBox,
      [target.name]: target.value
    })
  }

  function handleContainerSearch() {
    // Update search term when search component emits
    if (submittedSearchTerm !== searchTerm) {
      setSubmittedSearchTerm(searchTerm)
      setContainerTableOffset(0)
    }
  }

  function handleDaysInactiveSearch() {
    // Update search term when search component emits
    if (submittedDaysInactive !== daysInactive) {
      setSubmittedDaysInactive(daysInactive)
      setContainerTableOffset(0)
    }
  }

  function handleShowHideAdditionalFilters() {
    setDaysInactive('')
    setSubmittedDaysInactive('')
    setShowAdditionalFilters(!showAdditionalFilters)
  }

  async function handleSubmit(evt) {
    evt.preventDefault()
    setCreateContainerLoading(true)
    configuredAxios.post(CONTAINERS_URL, containerBox).then((res) => {
      history.push('/container_box/' + res.data.id)
      setCreateContainerLoading(false)
      toast.success('Container Created Successfully')
    }).catch((err) => {
      console.log(err)
      if (err.response) {
        if (err.response.status === 400) {
          setErrors(err.response.data)
        }
      } else {
        toast.success('Container could not be created')
      }
      setCreateContainerLoading(false)
    })
  }

  useEffect(() => {
    const searchParams = new URLSearchParams({
      offset: containerTableOffset,
      limit: containerTableLimit,
      searchTerm: submittedSearchTerm,
      orderBy: containerTableSortOption,
      orderDirection: containerTableSortDirection
    })
    if (submittedDaysInactive && showAdditionalFilters) {
      searchParams.append('daysInactive', submittedDaysInactive)
    }
    configuredAxios.get(CONTAINERS_SEARCH_URL, { params: searchParams }).then((res) => {
      setContainerBoxes(res.data.containers)
      setContainerTotalCount(res.data.totalCount)
    }).catch((err) => {
      console.log(err)
      toast.error('Could not retrieve ContainerBoxes')
      setContainerBoxes([])
      setContainerTotalCount(0)
    })
  }, [containerTableLimit, containerTableOffset, containerTableSortDirection, containerTableSortOption, showAdditionalFilters, submittedDaysInactive, submittedSearchTerm])

  let additionalFiltersText = '+ Show Additional Filters'
  let additionalFilters = (<></>)
  if (showAdditionalFilters) {
    additionalFiltersText = '- Hide Additional Filters'
    additionalFilters = (
      <>
        <Row className='p-2 mb-2 border rounded no-gutters'>
          <Col xs={4}>
            <Form.Label>Container inactive for at least (# of days)</Form.Label>
            <Search
              type='number'
              placeholder='# of days'
              name='daysInactive'
              searchTerm={daysInactive}
              onChange={(st) => { setDaysInactive(st) }}
              onSearch={() => { handleDaysInactiveSearch() }}
            />
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Container>
      <h2>Containers</h2>
      <Container>
        <Row className='no-gutters'>
          <Col>
            <ContainerBoxForm
              loading={createContainerLoading}
              containerBox={containerBox}
              onChange={handleChange}
              onSubmit={handleSubmit}
              errors={errors}
            />
          </Col>
        </Row>
        <Row className='mt-5 align-items-md-center no-gutters justify-content-md-between'>
          <Col className='mb-2' xl={4}>
            <Search
              searchTerm={searchTerm}
              onChange={(st) => { setSearchTerm(st) }}
              onSearch={() => { handleContainerSearch() }}
            />
          </Col>
          <Col md='auto' className='mb-2'>
            <TablePaginator
              offset={containerTableOffset}
              itemsPerPage={containerTableLimit}
              totalItems={containerTotalCount}
              onItemsPerPageSelect={(limit) => { setContainerTableLimit(limit) }}
              onNextPage={() => { setContainerTableOffset(containerTableOffset + containerTableLimit) }}
              onPreviousPage={() => { setContainerTableOffset(containerTableOffset - containerTableLimit) }}
              onFirstPage={() => { setContainerTableOffset(0) }}
              onLastPage={() => setContainerTableOffset(containerTotalCount - containerTableLimit)}
            />
          </Col>
          <Col md='auto' className='mb-2'>
            <TableSorter
              sortOptions={{
                containerNumber: 'Container Number',
                containerSize: 'Container Size',
                customerName: 'Customer Name',
                lastMoved: 'Last Moved',
                locationAddress: 'Location Address',
                locationCity: 'Location City',
                locationState: 'Location State',
                locationZip: 'Location Zip',
                containerDescription: 'Container Description'
              }}
              sortDirection={containerTableSortDirection}
              onToggleDirection={(sortDirection) => { setContainerTableSortDirection(!sortDirection) }}
              sortBy={containerTableSortOption}
              onSortOptionChange={(value) => { setContainerTableSortOption(value) }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                color: '#f8890c',
                cursor: 'pointer',
                userSelect: 'none',
                width: 'fit-content'
              }}
              className='mb-2'
              onClick={() => { handleShowHideAdditionalFilters() }}
            >
              {additionalFiltersText}
            </div>
            {additionalFilters}
          </Col>
        </Row>
        <Row>
          <Col>
            <ContainerBoxList containerBoxes={containerBoxes} highlightedSearchTerm={submittedSearchTerm} />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default ContainerBoxPage
