import React, { useState, useRef, useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { DateTime, Interval } from 'luxon'

import LocationDataSelectInput from '../locations/LocationDataSelectInput'
import StaticSelectInput from '../common/StaticSelectInput'
import {
  ADD_TO_DAILY_RUNS,
  IS_RECID_UNIQUE_URL
} from '../api_urls'

import { MGE_CONTAINER_NUM, MGE_LOCATION_ID, NO_CONTAINER_NAME } from '../../globals'
import { compareContainerNumbers, filterOption } from '../util'
import configuredAxios from '../../configured-axios'

function RunForm(props) {
  const empty_run = {
    dailyRunId: props.dailyRunId,
    pickUpId: null,
    dropOffId: null,
    locationId: '',
    description: '',
    complete: false,
    recurring: false,
    recurrenceId: '',
    recPattern: {
      pattern: '',
      frequency: '',
      duration: ''
    }
  }

  const [run, setRun] = useState(empty_run)
  const [recurrenceId, setRecurrenceId] = useState('')
  const [errors, setErrors] = useState({})
  const renderRef = useRef()

  const [availablePickUp, setAvailablePickup] = useState([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }])
  const [availableDropOff, setAvailableDropOff] = useState([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }])

  useEffect(() => {
    const updatedRun = { ...run }
    updatedRun.pickUpId = null
    updatedRun.dropOffId = null
    updatedRun.pickUp = null
    updatedRun.dropOff = null
    setRun(updatedRun)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run.locationId])

  useEffect(() => {
    let availPickup = []
    let availDropoff = []
    if (run && props.containers.length > 0) {
      availPickup = props.containers?.reduce((acc, c) => {
        if (c.histories[0]?.locationId === run.locationId && c.id !== MGE_CONTAINER_NUM) {
          acc.push({ id: c.id, number: c.number })
        }
        return acc
      }, [])

      availDropoff = props.containers?.reduce((acc, c) => {
        if (c.histories[0]?.locationId === MGE_LOCATION_ID && c.id !== MGE_CONTAINER_NUM) {
          acc.push({ id: c.id, number: c.number })
        }
        return acc
      }, [])
    }
    setAvailablePickup([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }].concat(availPickup).sort(compareContainerNumbers))
    setAvailableDropOff([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }].concat(availDropoff).sort(compareContainerNumbers))
  }, [run, props.containers])

  function validForm() {
    const _errors = {}

    const maxDate = DateTime.local().plus({ months: 3 }).setZone('America/New_York')

    if (!run.locationId) _errors.locationId = 'Location is required'

    if (run.recurring) {
      const { recPattern } = run
      if (recPattern.pattern) {
        const { pattern, frequency, duration } = recPattern

        if (pattern === 'daily') {
          const maxDays = Math.round(
            Interval.fromDateTimes(DateTime.local(), maxDate).length(
              'days'
            )
          )

          if (frequency.interval) {
            const { days } = frequency.interval
            if (days === '') {
              _errors.days = 'Daily interval is required'
            }
          }

          if (parseInt(duration) > maxDays) {
            _errors.duration = `Duration must not exceed ${maxDays} days (3 months from current date)`
          }
        }

        if (pattern === 'weekly') {
          const { weeks, daysOfWeek } = frequency

          const maxWeeks = Math.round(
            Interval.fromDateTimes(DateTime.local(), maxDate).length(
              'weeks'
            )
          )

          if (weeks === '') {
            _errors.weeks = 'Weekly interval is required'
          }

          if (!Object.values(daysOfWeek).includes(true)) {
            _errors.daysOfWeek = 'Day of week is required'
          }

          if (parseInt(duration) > maxWeeks) {
            _errors.duration = `Duration must not exceed ${maxWeeks} weeks (3 months from current date)`
          }
        }

        if (pattern === 'monthly') {
          const { months } = frequency.interval
          if (frequency.type === 'dayOfMonth') {
            const { day } = frequency.interval

            if (day === '') {
              _errors.day = 'Day of month is required'
            }

            if (months === '') {
              _errors.months = 'Monthly interval is required'
            }

            if (parseInt(months) > 12 || parseInt(months) < 1) {
              _errors.months = 'Monthly interval must be 1 - 12'
            }
          }

          if (frequency.type === 'weekOfMonth') {
            if (months === '') {
              _errors.months = 'Monthly interval is required'
            }

            if (parseInt(months) > 12 || parseInt(months) < 1) {
              _errors.months = 'Monthly interval must be 1 - 12'
            }
          }
        }
      }
    }

    setErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  function handleChange(label, value) {
    setRun({
      ...run,
      [label]: value
    })
  }

  function handlePickUpChange(name, value, label) {
    const updatedRun = { ...run }
    if (updatedRun.dropOffId !== MGE_CONTAINER_NUM && updatedRun.pickUpId === updatedRun.dropOffId) {
      updatedRun.dropOff = null
      updatedRun.dropOffId = undefined
      updatedRun.complete = false
    }
    updatedRun.pickUp = value ? { value: value, label: label } : null
    updatedRun.pickUpId = value || undefined
    updatedRun.complete = value && run.dropOff ? updatedRun.complete : false
    setRun(updatedRun)
  }

  function handleDropOffChange(name, value, label) {
    setRun({
      ...run,
      dropOff: value ? { value: value, label: label } : null,
      dropOffId: value || undefined,
      complete: value && run.pickUp ? run.complete : false
    })
  }

  function handleTextChange({ target }) {
    setRun({
      ...run,
      [target.name]: target.value
    })
  }

  function handleCheckChange({ target }) {
    setRun({
      ...run,
      [target.name]: target.checked
    })
  }

  function handlePatternChange({ target }) {
    if (target.id === 'weekly') {
      setRun({
        ...run,
        recPattern: {
          pattern: 'weekly',
          duration: '',
          frequency: {
            weeks: '',
            daysOfWeek: {
              monday: false,
              tuesday: false,
              wednesday: false,
              thursday: false,
              friday: false
            }
          }
        }
      })
    } else {
      setRun({
        ...run,
        recPattern: {
          pattern: target.id,
          frequency: '',
          duration: ''
        }
      })
    }
  }

  function handleDurationChange({ target }) {
    setRun({
      ...run,
      recPattern: {
        duration: target.value,
        pattern: run.recPattern.pattern,
        frequency: run.recPattern.frequency
      }
    })
  }

  function handleDailyFrequency({ target }) {
    setErrors({})
    if (target.id === 'dailyInterval') {
      setRun({
        ...run,
        recPattern: {
          pattern: 'daily',
          duration: '',
          frequency: {
            type: 'daysToRepeat',
            interval: {
              days: ''
            }
          }
        }
      })
    } else if (target.id === 'everyWeekday') {
      setRun({
        ...run,
        recPattern: {
          pattern: 'daily',
          duration: '',
          frequency: {
            type: 'weekdays'
          }
        }
      })
    }
  }

  function handleIntervalTextChange({ target }) {
    setRun({
      ...run,
      recPattern: {
        pattern: 'daily',
        duration: run.recPattern.duration,
        frequency: {
          type: 'daysToRepeat',
          interval: {
            days: target.value
          }
        }
      }
    })
  }

  function handleWeeksText({ target }) {
    setRun({
      ...run,
      recPattern: {
        pattern: 'weekly',
        duration: run.recPattern.duration,
        frequency: {
          weeks: target.value,
          daysOfWeek: {
            ...run.recPattern.frequency.daysOfWeek
          }
        }
      }
    })
  }

  // this is for weekly weekday checkbox selection
  function handleWeekdayChange({ target }) {
    setRun({
      ...run,
      recPattern: {
        pattern: 'weekly',
        duration: run.recPattern.duration,
        frequency: {
          weeks: run.recPattern.frequency.weeks,
          daysOfWeek: {
            ...run.recPattern.frequency.daysOfWeek,
            [target.name]: target.checked
          }
        }
      }
    })
  }

  function handleMonthlyFrequency({ target }) {
    setErrors({})
    if (target.id === 'dayOfMonth') {
      setRun({
        ...run,
        recPattern: {
          pattern: 'monthly',
          frequency: {
            type: 'dayOfMonth',
            interval: {
              day: '',
              months: ''
            }
          }
        }
      })
    } else if (target.id === 'weekOfMonth') {
      setRun({
        ...run,
        recPattern: {
          pattern: 'monthly',
          frequency: {
            type: 'weekOfMonth',
            interval: {
              week: 'first',
              weekday: 'Monday',
              months: ''
            }
          }
        }
      })
    }
  }

  function handleMonthlyIntervalChange({ target }) {
    if (run.recPattern.frequency.type === 'dayOfMonth') {
      setRun({
        ...run,
        recPattern: {
          pattern: 'monthly',
          frequency: {
            type: 'dayOfMonth',
            interval: {
              ...run.recPattern.frequency.interval,
              [target.name]: target.value
            }
          }
        }
      })
    } else if (run.recPattern.frequency.type === 'weekOfMonth') {
      setRun({
        ...run,
        recPattern: {
          pattern: 'monthly',
          frequency: {
            type: 'weekOfMonth',
            interval: {
              ...run.recPattern.frequency.interval,
              [target.name]: target.value
            }
          }
        }
      })
    }
  }

  async function handleSubmit(evt) {
    if (evt) {
      evt.preventDefault()
    }

    if (!validForm()) return

    if (run.recurring) {
      const id = Math.floor(Math.random() * 89999 + 10000).toString()

      const isUnique = await configuredAxios.post(IS_RECID_UNIQUE_URL, {
        recurrenceId: id
      })

      if (isUnique.data) {
        handleChange('recurrenceId', id)
        setRecurrenceId(id)
      } else {
        handleSubmit()
      }
    } else {
      const postRun = async (_run) => {
        try {
          await configuredAxios.post(ADD_TO_DAILY_RUNS, _run)
          setRun(empty_run)
          props.handleShowForm(false)
          toast.success('Run Created Successfully')
        } catch (error) {
          toast.error('Run could not be created')
        }
      }
      postRun(run)
      props.handleRefresh()
    }
  }

  useEffect(() => {
    if (!renderRef.current) {
      renderRef.current = true
    } else {
      const postRun = async (_run) => {
        try {
          await configuredAxios.post(ADD_TO_DAILY_RUNS, _run)
          setRun(empty_run)
          props.handleShowForm(false)
          toast.success('Run Created Successfully')
        } catch (error) {
          toast.error('Run could not be created')
        }
      }
      postRun(run)
      props.handleRefresh()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recurrenceId])

  return (
    <Form onSubmit={handleSubmit} className='run-form'>
      <Form.Row>
        <LocationDataSelectInput
          label='Location'
          name='locationId'
          value={run.locationId}
          handleChange={handleChange}
        />
      </Form.Row>
      {errors.locationId && (
        <Form.Row>
          <Alert variant='danger'>{errors.locationId}</Alert>
        </Form.Row>
      )}
      <Form.Row>
        <StaticSelectInput
          collection={availablePickUp}
          label='Pick Up: '
          name='pickUpId'
          value={run.pickUp}
          handleChange={handlePickUpChange}
          select_label='number'
          filterOption={filterOption}
          isClearable
        />
      </Form.Row>
      <Form.Row>
        <StaticSelectInput
          collection={run.pickUp && run.pickUpId !== MGE_CONTAINER_NUM ? availableDropOff.concat({ id: run.pickUp.value, number: run.pickUp.label }).sort(compareContainerNumbers) : availableDropOff}
          label='Drop Off: '
          name='dropOffId'
          value={run.dropOff}
          handleChange={handleDropOffChange}
          select_label='number'
          filterOption={filterOption}
          isClearable
        />
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId='exampleForm.ControlTextarea1'>
          <Form.Label>Notes</Form.Label>
          <Form.Control
            as='textarea'
            rows='3'
            name='description'
            value={run.description}
            onChange={handleTextChange}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Check
          disabled={run.dropOff === null || run.pickUp === null}
          label='Complete?'
          name='complete'
          onChange={handleCheckChange}
          value={run.complete}
          checked={run.complete}
        />
      </Form.Row>
      <Form.Row>
        <Form.Check
          label='Recurring?'
          name='recurring'
          onChange={handleCheckChange}
        />
      </Form.Row>

      {/* Recurring options */}
      {run.recurring && (
        <>
          <Alert variant='warning'>
            If the Daily Run you are within is a for a previous date,
            the recurring runs will NOT be predated within this Daily
            Run.
          </Alert>
          <Form.Row>
            <h6 className='sub-header'>Recurrence Pattern</h6>
          </Form.Row>
          <Form.Row>
            <Form.Check
              type='radio'
              label='Daily'
              name='recPattern'
              id='daily'
              onChange={handlePatternChange}
            />
            <Form.Check
              className='check-margin'
              type='radio'
              label='Weekly'
              name='recPattern'
              id='weekly'
              onChange={handlePatternChange}
            />
            <Form.Check
              className='check-margin'
              type='radio'
              label='Monthly'
              name='recPattern'
              id='monthly'
              onChange={handlePatternChange}
            />
          </Form.Row>
          <h6 className='sub-header'>Frequency</h6>

          {/* Daily pattern form */}
          {run.recPattern.pattern === 'daily' && (
            <>
              <Form.Row className='rec-form-row'>
                <Form.Check
                  type='radio'
                  label='Every'
                  name='dailyFrequency'
                  id='dailyInterval'
                  onChange={handleDailyFrequency}
                />
                {run.recPattern.frequency.type === 'daysToRepeat' ? (
                  <>
                    <Form.Control
                      className='rec-text'
                      type='text'
                      name='days'
                      onChange={handleIntervalTextChange}
                    />
                  </>
                ) : (
                  <Form.Control
                    className='rec-text'
                    disabled
                    type='text'
                    name='days'
                  />
                )}
                <p>day(s) for the next</p>
                {run.recPattern.frequency.type === 'daysToRepeat' ? (
                  <Form.Control
                    className='rec-text'
                    type='text'
                    name='dailyDuration'
                    onChange={handleDurationChange}
                  />
                ) : (
                  <Form.Control
                    className='rec-text'
                    type='text'
                    name='dailyDuration'
                    disabled
                  />
                )}
                <p>day(s)</p>
              </Form.Row>
              {errors.days && (
                <Form.Row>
                  <Alert variant='danger'>{errors.days}</Alert>
                  {run.recPattern.frequency.type === 'daysToRepeat' &&
                    errors.duration && (
                    <Alert
                        variant='danger'
                        style={{ marginLeft: '5px' }}
                      >
                        {errors.duration}
                      </Alert>
                  )}
                </Form.Row>
              )}
              <Form.Row>
                <Form.Check
                  type='radio'
                  label='Every weekday'
                  name='dailyFrequency'
                  id='everyWeekday'
                  onChange={handleDailyFrequency}
                />
                <p>&nbsp;for the next</p>
                {run.recPattern.frequency.type === 'weekdays' ? (
                  <Form.Control
                    className='rec-text'
                    type='text'
                    name='dailyDuration'
                    onChange={handleDurationChange}
                  />
                ) : (
                  <Form.Control
                    className='rec-text'
                    type='text'
                    name='dailyDuration'
                    disabled
                  />
                )}
                <p>day(s)</p>
              </Form.Row>
              {run.recPattern.frequency.type === 'weekdays' &&
                errors.duration && (
                <Form.Row>
                    <Alert variant='danger'>{errors.duration}</Alert>
                  </Form.Row>
              )}
            </>
          )}

          {/* Weekly pattern form */}
          {run.recPattern.pattern === 'weekly' && (
            <>
              <Form.Row className='rec-form-row'>
                <p>Recur every</p>
                <Form.Control
                  className='rec-text'
                  type='text'
                  name='weeksToRepeat'
                  onChange={handleWeeksText}
                />
                <p>week(s) on:</p>
              </Form.Row>
              {errors.weeks && (
                <Form.Row>
                  <Alert variant='danger'>{errors.weeks}</Alert>
                </Form.Row>
              )}
              <Form.Row>
                <Form.Check
                  label='Monday'
                  name='monday'
                  onChange={handleWeekdayChange}
                />
                <Form.Check
                  className='check-margin'
                  label='Tuesday'
                  name='tuesday'
                  onChange={handleWeekdayChange}
                />
                <Form.Check
                  className='check-margin'
                  label='Wednesday'
                  name='wednesday'
                  onChange={handleWeekdayChange}
                />
                <Form.Check
                  className='check-margin'
                  label='Thursday'
                  name='thursday'
                  onChange={handleWeekdayChange}
                />
                <Form.Check
                  className='check-margin'
                  label='Friday'
                  name='friday'
                  onChange={handleWeekdayChange}
                />
              </Form.Row>
              {errors.daysOfWeek && (
                <Form.Row>
                  <Alert variant='danger'>{errors.daysOfWeek}</Alert>
                </Form.Row>
              )}
              <Form.Row className='duration-row'>
                <p>for the next</p>
                <Form.Control
                  className='rec-text'
                  type='text'
                  name='weeklyDuration'
                  onChange={handleDurationChange}
                />
                <p>week(s)</p>
              </Form.Row>
              {errors.duration && (
                <Form.Row>
                  <Alert variant='danger'>{errors.duration}</Alert>
                </Form.Row>
              )}
            </>
          )}

          {/* Monthly pattern form */}
          {run.recPattern.pattern === 'monthly' && (
            <>
              <Form.Row className='rec-form-row'>
                <Form.Check
                  id='dayOfMonth'
                  type='radio'
                  name='monthlyFrequency'
                  onChange={handleMonthlyFrequency}
                />
                {run.recPattern.frequency.type === 'dayOfMonth' ? (
                  <Form.Control
                    className='rec-text'
                    type='text'
                    name='day'
                    onChange={handleMonthlyIntervalChange}
                  />
                ) : (
                  <Form.Control
                    className='rec-text'
                    disabled
                    type='text'
                    name='day'
                  />
                )}
                <p>day of every month for the next</p>
                {run.recPattern.frequency.type === 'dayOfMonth' ? (
                  <Form.Control
                    className='rec-text'
                    type='text'
                    name='months'
                    onChange={handleMonthlyIntervalChange}
                  />
                ) : (
                  <Form.Control
                    className='rec-text'
                    disabled
                    type='text'
                    name='months'
                  />
                )}
                <p>month(s)</p>
              </Form.Row>
              {errors.day && (
                <Form.Row>
                  <Alert variant='danger'>{errors.day}</Alert>
                </Form.Row>
              )}
              {run.recPattern.frequency.type === 'dayOfMonth' &&
              errors.months ? (
                  <Form.Row>
                  <Alert variant='danger'>{errors.months}</Alert>
                </Form.Row>
                ) : (
                  ''
                )}
              <Form.Row
                className='rec-form-row'
                style={{ marginTop: '5px' }}
              >
                <Form.Check
                  id='weekOfMonth'
                  type='radio'
                  label='The'
                  name='monthlyFrequency'
                  onChange={handleMonthlyFrequency}
                />
                {run.recPattern.frequency.type === 'weekOfMonth' ? (
                  <Form.Control
                    className='rec-text'
                    as='select'
                    id='weekInterval'
                    name='week'
                    onChange={handleMonthlyIntervalChange}
                  >
                    <option>first</option>
                    <option>second</option>
                    <option>third</option>
                    <option>fourth</option>
                    {props.weekdayCount[
                      run.recPattern.frequency.interval.weekday.toLowerCase()
                    ] > 4 && <option>fifth</option>}
                  </Form.Control>
                ) : (
                  <Form.Control
                    className='rec-text'
                    disabled
                    as='select'
                    id='weekInterval'
                    name='week'
                  >
                    <option>first</option>
                    <option>second</option>
                    <option>third</option>
                    <option>fourth</option>
                  </Form.Control>
                )}
                {run.recPattern.frequency.type === 'weekOfMonth' ? (
                  <Form.Control
                    className='rec-text'
                    as='select'
                    id='weekdayInterval'
                    name='weekday'
                    onChange={handleMonthlyIntervalChange}
                  >
                    <option>Monday</option>
                    <option>Tuesday</option>
                    <option>Wednesday</option>
                    <option>Thursday</option>
                    <option>Friday</option>
                  </Form.Control>
                ) : (
                  <Form.Control
                    className='rec-text'
                    disabled
                    as='select'
                    id='weekdayInterval'
                    name='weekday'
                  >
                    <option>Monday</option>
                    <option>Tuesday</option>
                    <option>Wednesday</option>
                    <option>Thursday</option>
                    <option>Friday</option>
                  </Form.Control>
                )}
                <p>of every month for the next</p>
                {run.recPattern.frequency.type === 'weekOfMonth' ? (
                  <Form.Control
                    className='rec-text'
                    type='text'
                    id='monthInterval'
                    name='months'
                    onChange={handleMonthlyIntervalChange}
                  />
                ) : (
                  <Form.Control
                    className='rec-text'
                    disabled
                    type='text'
                    id='monthInterval'
                    name='months'
                  />
                )}
                <p>month(s)</p>
              </Form.Row>
              {run.recPattern.frequency.type === 'weekOfMonth' &&
              errors.months ? (
                  <Form.Row>
                  <Alert variant='danger'>{errors.months}</Alert>
                </Form.Row>
                ) : (
                  ''
                )}
            </>
          )}
        </>
      )}
      <Button variant='primary' type='submit' className='float-right'>
        Submit
      </Button>
    </Form>
  )
}

export default RunForm
