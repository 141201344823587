import { DateTime } from 'luxon'

function dmyString(_date) {
  const date = DateTime.fromISO(_date, { zone: 'utc' })
  if (date.isValid) {
    return date.toLocaleString('en-US', { timeZone: 'America/New_York' })
  } else {
    return ''
  }
}

function shallowClone(obj) {
  return Object.assign({}, obj)
}

export { dmyString, shallowClone }
