import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import CustomerList from './customers/CustomerList'
import CustomerForm from './customers/CustomerForm'
import Search from './common/Search'
import { CUSTOMERS_URL, CUSTOMERS_SEARCH_URL } from './api_urls'
import configuredAxios from '../configured-axios'
import TablePaginator from './common/TablePaginator'
import TableSorter from './common/TableSorter'

function CustomerPage() {
  const emptyCustomer = {
    name: '',
    number: '',
    locations: []
  }

  const [customer, setCustomer] = useState(emptyCustomer)
  const [customers, setCustomers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const [customerTableLimit, setCustomerTableLimit] = useState(20)
  const [customerTableOffset, setCustomerTableOffset] = useState(0)
  const [customerTableSortOption, setCustomerTableSortOption] = useState('customerName')
  const [customerTableSortDirection, setCustomerTableSortDirection] = useState(true)
  const [customerTotalCount, setCustomerTotalCount] = useState(0)

  const [submittedSearchTerm, setSubmittedSearchTerm] = useState('')

  const history = useHistory()

  function handleCustomerFormSubmit() {
    // Create a new customer when customer form is submitted
    configuredAxios.post(CUSTOMERS_URL, customer).then((res) => {
      history.push(`customer/${res.data.id}`)
      toast.success('Customer Created Successfully')
    }).catch((err) => {
      toast.error('Customer could not be created')
      console.log(err)
    })
  }

  function handleCustomerSearch() {
    // Update search term when search component emits
    if (submittedSearchTerm !== searchTerm) {
      setSubmittedSearchTerm(searchTerm)
      setCustomerTableOffset(0)
    }
  }

  useEffect(() => {
    // Grab data if limit, offset, sort direction, sort option, or search term change
    const searchParams = new URLSearchParams({
      offset: customerTableOffset,
      limit: customerTableLimit,
      searchTerm: submittedSearchTerm,
      orderBy: customerTableSortOption,
      orderDirection: customerTableSortDirection
    })
    configuredAxios.get(CUSTOMERS_SEARCH_URL, { params: searchParams }).then((res) => {
      setCustomers(res.data.customers)
      setCustomerTotalCount(res.data.totalCount)
    }).catch((err) => {
      toast.error('Could not retrieve Customers')
      setCustomers([])
      setCustomerTotalCount(0)
      console.log(err)
    })
  }, [customerTableLimit, customerTableOffset, customerTableSortDirection, customerTableSortOption, submittedSearchTerm])

  return (
    <Container>
      <h2>Customers</h2>
      <Container>
        <Row>
          <Col>
            <CustomerForm
              customer={customer}
              onSubmit={handleCustomerFormSubmit}
              onChange={(c) => { setCustomer(c) }}
            />
          </Col>
        </Row>
        <Row className='mt-5 align-items-md-center no-gutters justify-content-md-between'>
          <Col className='mb-2' xl={4}>
            <Search
              searchTerm={searchTerm}
              onChange={(st) => { setSearchTerm(st) }}
              onSearch={() => { handleCustomerSearch() }}
            />
          </Col>
          <Col md='auto' className='mb-2'>
            <TablePaginator
              offset={customerTableOffset}
              itemsPerPage={customerTableLimit}
              totalItems={customerTotalCount}
              onItemsPerPageSelect={(limit) => { setCustomerTableLimit(limit) }}
              onNextPage={() => { setCustomerTableOffset(customerTableOffset + customerTableLimit) }}
              onPreviousPage={() => { setCustomerTableOffset(customerTableOffset - customerTableLimit) }}
              onFirstPage={() => { setCustomerTableOffset(0) }}
              onLastPage={() => setCustomerTableOffset(customerTotalCount - customerTableLimit)}
            />
          </Col>
          <Col md='auto' className='mb-2'>
            <TableSorter
              sortOptions={{
                customerName: 'Customer Name',
                customerNumber: 'Customer Number',
                locationContact: 'Contact Name',
                locationAddress: 'Address',
                locationCity: 'City',
                locationNickname: 'Location Nickname'
              }}
              sortDirection={customerTableSortDirection}
              onToggleDirection={(sortDirection) => { setCustomerTableSortDirection(!sortDirection) }}
              sortBy={customerTableSortOption}
              onSortOptionChange={(value) => { setCustomerTableSortOption(value) }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <CustomerList customers={customers} highlightedSearchTerm={submittedSearchTerm} />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default CustomerPage
