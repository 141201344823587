import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Container, Row, Col, Button } from 'react-bootstrap'
import LocationList from '../locations/LocationList'
import { CUSTOMERS_URL, LOCATIONS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'
import CustomerForm from './CustomerForm'
import { Plus } from 'react-bootstrap-icons'
import LocationForm from '../locations/LocationForm'
import LocationUpdateModal from '../locations/LocationUpdateModal'

function Customer() {
  const emptyCustomer = {
    name: '',
    number: '',
    locations: []
  }

  const defaultLocationData = {
    address: '',
    address2: '',
    city: '',
    state: 'OH', // Default state is Ohio
    zip: '',
    name: '',
    phone: '',
    nickname: ''
  }

  const { id } = useParams()
  const [customer, setCustomer] = useState(emptyCustomer)
  const [locations, setLocations] = useState([])
  const [newLocation, setNewLocation] = useState(defaultLocationData)
  const [newLocationErrors, setLocationFormErrors] = useState({})
  const [locationToUpdate, setLocationToUpdate] = useState(defaultLocationData)
  const [locationToUpdateErrors, setLocationToUpdateErrors] = useState({})
  const [showLocationCreateForm, setShowLocationCreateForm] = useState(false)
  const [showLocationEditModal, setShowLocationEditModal] = useState(false)

  function handleCustomerFormSubmit() {
    configuredAxios.put(`${CUSTOMERS_URL}/${id}`, customer).then((res) => {
      toast.success(`Customer ${customer.number} Updated Successfully`)
      setCustomer(res.data)
    }).catch((err) => {
      toast.error('Customer could not be created')
      console.log(err)
    })
  }

  function handleLocationFormSubmit() {
    const errors = validateLocation(newLocation)
    setLocationFormErrors(errors)
    if (Object.keys(errors).length === 0) {
      newLocation.customerId = id
      configuredAxios.post(LOCATIONS_URL, newLocation).then((res) => {
        toast.success('Location Created Successfully')
        setNewLocation(defaultLocationData)
        const newLocations = locations.slice()
        newLocations.push(res.data)
        setLocations(newLocations)
      }).catch((err) => {
        toast.error('Location could not be created')
        console.log(err)
      })
    }
  }

  function handleLocationUpdateModalSubmit() {
    const errors = validateLocation(locationToUpdate)
    setLocationToUpdateErrors(errors)
    if (Object.keys(errors).length === 0) {
      locationToUpdate.customerId = id

      configuredAxios.put(`${LOCATIONS_URL}/${locationToUpdate.id}`, locationToUpdate).then((res) => {
        toast.success('Location Updated Successfully')

        const newLocations = locations.slice().filter((location) => {
          return location.id !== locationToUpdate.id
        })
        newLocations.push(res.data)
        setLocations(newLocations)

        setLocationToUpdate(defaultLocationData)
        setShowLocationEditModal(false)
      }).catch((err) => {
        toast.error('Location could not be updated')
        console.log(err)
      })
    }
  }

  function handleUpdateLocation(location) {
    setLocationToUpdate(location)
    setShowLocationEditModal(true)
  }

  function validateLocation(location) {
    const errors = {}

    if (!location.address) errors.address = 'Address is required'
    if (!location.city) errors.city = 'City is required!'
    if (!location.zip) errors.zip = 'Zip is required!'

    return errors
  }

  async function handleDeleteLocation(location) {
    configuredAxios.delete(`${LOCATIONS_URL}/${location.id}`).then((res) => {
      toast.success('Deleted Address: ' + (location.nickname || location.address))
      const newLocations = locations.slice().filter((l) => {
        return l.id !== location.id
      })
      setLocations(newLocations)
    }).catch((err) => {
      toast.error('Could not delete address')
      console.log(err)
    })
  }

  function clearUpdateModalForm() {
    setShowLocationEditModal(false)
    setLocationToUpdate(defaultLocationData)
  }

  useEffect(() => {
    configuredAxios.get(`${CUSTOMERS_URL}/${id}`).then((res) => {
      setCustomer(res.data)
      setLocations(res.data.locations)
    }).catch((err) => {
      toast.error('Could not retrieve Customer with id: ' + id)
      console.log(err)
    })
  }, [id])

  return (
    <Container>
      <Row><Col><h2>Edit Customer</h2></Col></Row>
      <Row className='mb-5'>
        <Col>
          <CustomerForm
            customer={customer}
            onSubmit={handleCustomerFormSubmit}
            onChange={(c) => { setCustomer(c) }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            Locations&nbsp;
            <Plus onClick={() => setShowLocationCreateForm(!showLocationCreateForm)} />
          </h4>
        </Col>
      </Row>
      {showLocationCreateForm && (
        <Row className='mb-3'>
          <Col>
            <LocationForm
              location={newLocation}
              locationErrors={newLocationErrors}
              onChange={(l) => { setNewLocation(l) }}
              onSubmit={handleLocationFormSubmit}
            />
            <Button onClick={handleLocationFormSubmit} variant='primary' type='submit' className='float-right'>
              Submit
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <LocationList
            sort
            locations={locations}
            customerId={id}
            onDeleteLocation={handleDeleteLocation}
            onEditLocation={handleUpdateLocation}
          />
        </Col>
      </Row>
      <LocationUpdateModal
        show={showLocationEditModal}
        location={locationToUpdate}
        onChange={(l) => setLocationToUpdate(l)}
        locationErrors={locationToUpdateErrors}
        onHide={() => { clearUpdateModalForm() }}
        onSubmit={handleLocationUpdateModalSubmit}
      />
    </Container>
  )
}

export default Customer
