import axios from 'axios'

const configuredAxios = axios.create({
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
  }
})

const createAxiosResponseInterceptor = () => {
  const interceptor = configuredAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      // Reject promise if usual error
      if (error.response.status !== 401) {
        return Promise.reject(error)
      }

      /*
      * When response code is 401, try to refresh the token.
      * Eject the interceptor so it doesn't loop in case
      * token refresh causes the 401 response.
      *
      * Must be re-attached later on or the token refresh will only happen once
      */
      configuredAxios.interceptors.response.eject(interceptor)

      return configuredAxios
        .post('/api-public/refresh', {
          refreshToken: localStorage.getItem('refreshToken')
        })
        .then((response) => {
          localStorage.setItem('accessToken', response.data.accessToken)
          localStorage.setItem('refreshToken', response.data.refreshToken)
          configuredAxios.defaults.headers.Authorization = 'Bearer ' + response.data.accessToken
          error.response.config.headers.Authorization =
                        'Bearer ' + response.data.accessToken
          // Retry the initial call, but with the updated token in the headers.
          // Resolves the promise if successful
          return configuredAxios(error.response.config)
        })
        .catch((error2) => {
          // Retry failed, clean up and reject the promise
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          window.location = '/login'
          return Promise.reject(error2)
        })
        .finally(createAxiosResponseInterceptor) // Re-attach the interceptor by running the method
    }
  )
}

createAxiosResponseInterceptor()

export default configuredAxios
