import React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'

function Checkbox(props) {
  return (
    <Form.Group as={Col} controlId={'formGrid' + props.name}>
      <Form.Check
        type='checkbox'
        checked={props.value}
        onChange={props.onChange}
        name={props.name}
        label={props.label}
      />
    </Form.Group>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default Checkbox
