import React, { useEffect, useState } from 'react'
import { Plus } from 'react-bootstrap-icons'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'

import DailyRunForm from './daily_run/DailyRunForm'
import DailyRunList from './daily_run/DailyRunList'
import { SIMPLE_CURRENT_DAILY_RUNS_URL, SIMPLE_PREVIOUS_DAILY_RUNS_URL, TRUCKS_URL, DAILY_RUNS_URL } from './api_urls'
import configuredAxios from '../configured-axios'

function RunPage() {
  const [currentDailyRuns, setCurrentDailyRuns] = useState([])
  const [previousDailyRuns, setPreviousDailyRuns] = useState([])
  const [showPrevious, setShowPrevious] = useState(false)
  const [trucks, setTrucks] = useState([])
  const [dailyRunFormErrors, setDailyRunFormErrors] = useState({})
  const currentDateString = DateTime.now().toISODate()
  const emptyDailyRun = {
    date: currentDateString,
    truck: null,
    code: ''
  }
  const [newDailyRun, setNewDailyRun] = useState(emptyDailyRun)

  // Get current daily runs on load
  useEffect(() => {
    configuredAxios.get(SIMPLE_CURRENT_DAILY_RUNS_URL).then((res) => {
      setCurrentDailyRuns(res.data)
    }).catch((err) => {
      toast.error('Could not retrieve daily runs')
      console.log(err)
    })
  }, [])

  // Get Truck list when daily run date changes
  useEffect(() => {
    if (newDailyRun.date) {
      const searchParams = new URLSearchParams({
        date: newDailyRun.date,
        operator: 'NotEqual',
        status: 'true'
      })
      configuredAxios.get(TRUCKS_URL, { params: searchParams }).then((res) => {
        setTrucks(res.data)
      }).catch((err) => {
        toast.error('Could not retrieve Trucks')
        console.log(err)
      })
    } else {
      setTrucks([])
    }
  }, [newDailyRun.date])

  function validateNewDailyRun(run) {
    const errors = {}

    if (!run.date) errors.date = 'Date is required'
    if (!run.truckId) errors.truck = 'Truck is required'

    return errors
  }

  function handleSubmitNewDailyRun() {
    const dailyRunData = {
      date: newDailyRun.date,
      truckId: newDailyRun.truck?.value
    }
    const errors = validateNewDailyRun(dailyRunData)
    if (Object.keys(errors).length === 0) {
      configuredAxios.post(DAILY_RUNS_URL, dailyRunData).then((res) => {
        toast.success('Run Created Successfully')
        const run = res.data

        if (new Date(run.date) >= new Date(currentDateString)) {
          const newCurrentRuns = currentDailyRuns.slice()
          newCurrentRuns.push(run)
          setCurrentDailyRuns(newCurrentRuns)
        } else {
          const newPreviousRuns = previousDailyRuns.slice()
          newPreviousRuns.push(run)
          setPreviousDailyRuns(newPreviousRuns)
        }

        const newTrucks = trucks.slice().filter((truck) => {
          return truck.id !== run.truckId
        })

        setTrucks(newTrucks)
        setNewDailyRun(emptyDailyRun)
      }).catch((err) => {
        toast.error('Run could not be created')
        console.log(err)
      })
    } else {
      setDailyRunFormErrors(errors)
    }
  }

  function handleDailyRunChange(dailyRun) {
    setDailyRunFormErrors({})
    setNewDailyRun(dailyRun)
  }

  function handleShowPreviousRuns() {
    if (previousDailyRuns.length === 0 && !showPrevious) {
      configuredAxios.get(SIMPLE_PREVIOUS_DAILY_RUNS_URL).then((res) => {
        setPreviousDailyRuns(res.data)
        setShowPrevious(true)
      }).catch((err) => {
        console.log(err)
      })
    } else {
      setShowPrevious(!showPrevious)
    }
  }

  return (
    <Container>
      <h2>Create a Daily Run</h2>
      <Container>
        <Row>
          <Col>
            <DailyRunForm
              truckList={trucks}
              onChange={(dailyRun) => { handleDailyRunChange(dailyRun) }}
              dailyRun={newDailyRun}
              errors={dailyRunFormErrors}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant='primary' onClick={handleSubmitNewDailyRun} className='float-right'>
              Submit
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Daily Runs</h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <DailyRunList dailyRuns={currentDailyRuns} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Previous Daily Runs &nbsp;
              <Plus onClick={() => { handleShowPreviousRuns() }} />
            </h4>
          </Col>
        </Row>
        {showPrevious && (
          <Row>
            <Col>
              <DailyRunList dailyRuns={previousDailyRuns} />
            </Col>
          </Row>
        )}
      </Container>
    </Container>
  )
}

export default RunPage
