import React, { useState, useEffect, useCallback } from 'react'
import { Truck } from 'react-bootstrap-icons'
import { RUNS_URL, RUN_PICTURE_DELETE_URL, RUN_PICTURE_LIST_URL, SIMPLE_CONTAINERS_URL } from '../api_urls'
import { Container, Row, Col, Button } from 'react-bootstrap'

import { toast } from 'react-toastify'
import { MGE_CONTAINER_NUM, MGE_LOCATION_ID, NO_CONTAINER_NAME } from '../../globals'
import { compareContainerNumbers, filterOption } from '../util'
import configuredAxios from '../../configured-axios'
import ReactSelect from 'react-select'
import ViewPicturesModal from '../runs/ViewPicturesModal'

function DailyRunHeader(props) {
  return (
    <div className='d-flex'>
      <div>
        <Truck size={32} className='truck' />
      </div>
      <div className='news-feed-truck-code'>{props.code}</div>
      <div className='news-feed-truck-description'>
        {props.truck.description}
      </div>
    </div>
  )
}

function Location({ location }) {
  return (
    <>
      <b>{location.customer.name}</b>
      <br />
      {location.address}
      <br />
      {location.city},&nbsp;
      {location.state}&nbsp;
      {location.zip}
      <br />
      {location.name} {location.phone}
      <br />
    </>
  )
}

function DailyRunRow(props) {
  const [run, setRun] = useState(props.run)
  const [availablePickUp, setAvailablePickup] = useState([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }])
  const [availableDropOff, setAvailableDropOff] = useState([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }])
  const [runPictureFilenames, setRunPictureFilenames] = useState([])
  const [showViewPicturesModal, setShowViewPicturesModal] = useState(false)

  function handlePickUpChange(name, value, label) {
    const editRun = async (_run) => {
      try {
        const URL = RUNS_URL + '/' + _run.id
        await configuredAxios.put(URL, _run)
        const updatedRun = { ...run }
        if (run.dropOffId !== MGE_CONTAINER_NUM && run.pickUpId === run.dropOffId && changesToRun.pickUpId !== run.dropOffId) {
          updatedRun.dropOff = null
          updatedRun.dropOffId = null
        }
        updatedRun.pickUp = value ? { id: value, number: label } : null
        updatedRun.pickUpId = value || null
        setRun(updatedRun)
        toast.success('Updated Run Successfully')
      } catch (error) {
        toast.error('Run could not be updated')
        setRun(props.run)
      }
    }

    const changesToRun = {
      id: run.id,
      pickUpId: value || null
    }

    if (run.dropOffId !== MGE_CONTAINER_NUM && run.pickUpId === run.dropOffId && value !== run.dropOffId) {
      changesToRun.dropOffId = null
    }
    editRun(changesToRun)
  }

  function handleDropOffChange(name, value, label) {
    const editRun = async (_run) => {
      try {
        const URL = RUNS_URL + '/' + _run.id
        await configuredAxios.put(URL, _run)
        const updatedRun = { ...run }

        updatedRun.dropOff = value ? { id: value, number: label } : null
        updatedRun.dropOffId = value || null
        setRun(updatedRun)
        toast.success('Updated Run Successfully')
      } catch (error) {
        toast.error('Run could not be updated')
        setRun(props.run)
      }
    }

    const changesToRun = {
      id: run.id,
      dropOffId: value || null
    }
    editRun(changesToRun)
  }

  const fetchPictureFilenames = useCallback(() => {
    const PICTURES_URL = RUN_PICTURE_LIST_URL + run.id
    configuredAxios.get(PICTURES_URL).then((res) => {
      setRunPictureFilenames(res.data)
    }).catch((err) => {
      console.log(err)
      toast.error('Could not find pictures for run ' + run.id)
    })
  }, [run.id])

  useEffect(() => {
    let availPickup = []
    let availDropoff = []
    fetchPictureFilenames()

    if (run && props.containers && props.containers.length > 0) {
      availPickup = props.containers?.reduce((acc, c) => {
        if (c.histories[0]?.locationId === run.locationId && c.id !== MGE_CONTAINER_NUM) {
          acc.push({ id: c.id, number: c.number })
        }
        return acc
      }, [])

      availDropoff = props.containers?.reduce((acc, c) => {
        if (c.histories[0]?.locationId === MGE_LOCATION_ID && c.id !== MGE_CONTAINER_NUM) {
          acc.push({ id: c.id, number: c.number })
        }
        return acc
      }, [])
    }

    setAvailablePickup([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }].concat(availPickup))
    setAvailableDropOff([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }].concat(availDropoff))
  }, [run, props.containers, fetchPictureFilenames])

  function getPickupContainers() {
    return availablePickUp
  }

  function getDropoffContainers() {
    if (run.pickUp && run.pickUpId !== MGE_CONTAINER_NUM) {
      return availableDropOff.concat({ id: run.pickUp.id, number: run.pickUp.number }).sort(compareContainerNumbers)
    } else {
      return availableDropOff
    }
  }

  function containerListToSelectList(containers) {
    return containers.map((c) => { return { value: c.id, label: c.number } })
  }

  function handleOpenViewPicturesModal() {
    fetchPictureFilenames()
    setShowViewPicturesModal(true)
  }

  function handlePictureDelete(filename) {
    configuredAxios.delete(`${RUN_PICTURE_DELETE_URL}/${run.id}?filename=${filename}`).then(() => {
      toast.success('Successfully deleted run picture.')
      fetchPictureFilenames()
    }).catch((err) => {
      console.log(err)
      toast.error('An error occurred when deleting the run picture.')
    })
  }

  let picturesButtonDisplayStyle = {}
  if (runPictureFilenames.length === 0) {
    picturesButtonDisplayStyle = {
      display: 'none'
    }
  }

  return (
    <Row className='mb-2 border-bottom'>
      <Col xl={1}>
        Address:
      </Col>
      <Col>
        <Location location={run.location} />
      </Col>
      <Col>
        <Container>
          <Row className='align-items-md-center no-gutters'>
            <Col xl={4}>
              Pick Up:
            </Col>
            <Col>
              <ReactSelect
                options={containerListToSelectList(getPickupContainers())}
                value={run.pickUp ? { value: run.pickUp.id, label: run.pickUp.number === 0 ? NO_CONTAINER_NAME : run.pickUp.number } : null}
                onChange={(res) => {
                  handlePickUpChange('pickUpId', res?.value, res?.label)
                }}
                filterOption={filterOption}
                isClearable
                isDisabled={run.complete}
              />
            </Col>
          </Row>
        </Container>
      </Col>
      <Col>
        <Container>
          <Row className='align-items-md-center no-gutters'>
            <Col xl={4}>
              Drop Off:
            </Col>
            <Col>
              <ReactSelect
                options={containerListToSelectList(getDropoffContainers())}
                value={run.dropOff ? { value: run.dropOff.id, label: run.dropOff.number === 0 ? NO_CONTAINER_NAME : run.dropOff.number } : null}
                onChange={(res) => {
                  handleDropOffChange('dropOffId', res?.value, res?.label)
                }}
                filterOption={filterOption}
                isClearable
                isDisabled={run.complete}
              />
            </Col>
          </Row>
        </Container>
      </Col>
      <Col xs={2}>
        <Container>
          <Row className='align-items-md-center no-gutters'>
            <Button onClick={() => { handleOpenViewPicturesModal() }} style={picturesButtonDisplayStyle}>Pictures</Button>
            <ViewPicturesModal
              show={showViewPicturesModal}
              onHide={() => { setShowViewPicturesModal(false) }}
              runId={run.id}
              runPictureFilenames={runPictureFilenames}
              onPictureDelete={(filename) => { handlePictureDelete(filename) }}
              authenticated={props.authenticated}
            />
          </Row>
        </Container>
      </Col>
    </Row>
  )
}

function DailyRun(props) {
  const [completedRuns, setCompletedRuns] = useState([])
  const [activeRuns, setActiveRuns] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [containers, setContainers] = useState([])

  useEffect(() => {
    const completedArr = []
    const activeArr = []

    props.runs.map((run) => {
      if (run.complete) {
        completedArr.push(run)
      } else if (!run.complete) {
        activeArr.push(run)
      }
    })

    setCompletedRuns(completedArr)
    setActiveRuns(activeArr)

    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(SIMPLE_CONTAINERS_URL)
        setContainers(response.data)
      } catch (error) {
        toast.error('Could not retrieve containers.')
        setContainers([])
      }
    }
    fetchData()
  }, [props.runs, refresh])

  return (
    <Container className='border-bottom border-secondary'>
      <DailyRunHeader {...props} />
      <Row>
        <Col xs={3}>
          <h6 className='runs-header'>Active Runs</h6>
        </Col>
      </Row>
      {!activeRuns.length > 0 ? (
        <p>No Active Runs Found</p>
      ) : (
        activeRuns.map((run, index) => <DailyRunRow run={run} key={index} containers={containers} authenticated={props.authenticated} />)
      )}
      <Row className='mt-3'>
        <Col xs={3}>
          <h6 className='runs-header'>Completed Runs</h6>
        </Col>
      </Row>
      {!completedRuns.length > 0 ? (
        <Row>
          <Col>
            <p>No Completed Runs Found</p>
          </Col>
        </Row>
      ) : (
        completedRuns.map((run, index) => <DailyRunRow run={run} key={index} authenticated={props.authenticated} isDisabled />)
      )}
    </Container>
  )
}

export default DailyRun
