import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { EnvelopeFill, EnvelopeOpen, Trash } from "react-bootstrap-icons";

function dateString(_date) {
  const date = new Date(_date);
  return date.getMonth() + 1 + "-" + date.getDate() + "-" + date.getFullYear();
}

function AlertUnreadTableRow(props) {
  return (
    <tr
      id={props.id}
      className="table-active"
      onClick={() => props.markRead(props.id)}
    >
      <td>
        <EnvelopeFill />
      </td>
      <td>
        <b>{dateString(props.readOn)}</b>
      </td>
      <td>
        <pre>
          <b>{props.body}</b>
        </pre>
      </td>
      <td>
        <Trash onClick={() => props.handleDestroy(props.id)} />
      </td>
    </tr>
  );
}

function AlertTableRow(props) {
  return (
    <tr id={props.id}>
      <td>
        <EnvelopeOpen />
      </td>
      <td>{dateString(props.readOn)}</td>
      <td>
        <pre>{props.body}</pre>
      </td>
      <td>
        <Trash onClick={() => props.handleDestroy(props.id)} />
      </td>
    </tr>
  );
}

function Rows(props) {
  if (props.alerts && props.alerts.length > 0) {
    return props.alerts.map((alert) => {
      if (alert.read) {
        return (
          <AlertTableRow
            {...alert}
            key={alert.id}
            markRead={props.markRead}
            handleDestroy={props.handleDestroy}
          />
        );
      } else {
        return (
          <AlertUnreadTableRow
            {...alert}
            key={alert.id}
            markRead={props.markRead}
            handleDestroy={props.handleDestroy}
          />
        );
      }
    });
  } else {
    return (
      <tr>
        <td colSpan="5">No Alerts Found</td>
      </tr>
    );
  }
}

function AlertList(props) {
  return (
    <Container className="alerts-list">
      <Table>
        <colgroup>
          <col style={{ width: "10%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "70%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Read</th>
            <th>Date</th>
            <th>Body</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <Rows
            alerts={props.alerts}
            markRead={props.markRead}
            handleDestroy={props.handleDestroy}
          />
        </tbody>
      </Table>
    </Container>
  );
}

export default AlertList;
