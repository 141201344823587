import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import LocationDataSelectInput from '../locations/LocationDataSelectInput'
import { MOVE_CONTAINER_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

export default function HistoryForm(props) {
  const [newLocationId, setNewLocationId] = useState(props.currentLocationId)

  async function handleSubmit(evt) {
    evt.preventDefault()

    const postMoveContainer = async () => {
      try {
        await configuredAxios.post(MOVE_CONTAINER_URL, {
          containerBoxId: props.containerBoxId,
          newLocationId: newLocationId,
          historyUpdate: true
        })
        props.handleShowForm(false)
        toast.success('Container moved Successfully')
      } catch (error) {
        toast.error('Container could not be moved')
      }
    }
    postMoveContainer()
    props.handleRefresh(true)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <LocationDataSelectInput
          label='Location'
          name='locationId'
          value={newLocationId}
          handleChange={(_, value) => {
            setNewLocationId(value)
          }}
        />
        <Button variant='primary' type='submit'>
          Move Container
        </Button>
      </Form.Row>
    </Form>
  )
}
HistoryForm.propTypes = {
  containerBoxId: PropTypes.number,
  currentLocationId: PropTypes.number,
  handleShowForm: PropTypes.func,
  handleRefresh: PropTypes.func
}
