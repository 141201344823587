import React from 'react'
import { ToastContainer } from 'react-toastify'
import Col from 'react-bootstrap/Col'
import { Route, Redirect } from 'react-router-dom'

import NavArea from './NavArea'
import Header from './Header'
import useAccessTokens from '../useTokens'

function PrivateRoute({ children, ...rest }) {
  const [tokens] = useAccessTokens()
  return (
    <Route
      {...rest}
      render={() =>
        tokens?.accessToken !== null ? (
          children
        ) : (
          <Redirect to={{ pathname: '/login' }} />
      )}
    />
  )
}

function AuthenticatedWrapper({ children, ...props }) {
  return (
    <PrivateRoute>
      <div className='h-100'>
        <ToastContainer autoClose={3000} hideProgressBar />
        <Header />
        <div className='main-container d-flex'>
          <Col md={2} className='navigation-area h-100 '>
            <NavArea />
          </Col>
          <Col>{children}</Col>
        </div>
      </div>
    </PrivateRoute>
  )
}

export default AuthenticatedWrapper
