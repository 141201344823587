import React from "react";
import Table from "react-bootstrap/Table";

import { dmyString } from "../../helper";

function RunTableRow(props) {
  return (
    <tr>
      <td>{dmyString(props.run_date)}</td>
      <td>{props.pickUpId ? props.pickUp.number : ""}</td>
      <td>{props.dropOffId ? props.dropOff.number : ""}</td>
      <td>{props.complete ? "Complete" : "Incomplete"}</td>
    </tr>
  );
}

function Rows(props) {
  if (props.runs && props.runs.length > 0) {
    return props.runs.map((run) => {
      return <RunTableRow {...run} key={run.id} run_date={props.run_date} />;
    });
  } else {
    return <RunTableRow run_date={props.run_date} />;
  }
}

export default function RunTableList(props) {
  return (
    <div className="runs-table-list">
      <h4>Runs</h4>
      <Table hover className="">
        <thead>
          <tr>
            <th>Date</th>
            <th>Pickup Container</th>
            <th>Dropoff Container</th>
            <th>Completed</th>
          </tr>
        </thead>
        <tbody>
          {props.daily_runs.map((daily_run) => {
            return (
              <Rows
                key={daily_run.id}
                run_date={daily_run.date}
                runs={daily_run.runs}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}
