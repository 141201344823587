/* eslint-disable react/jsx-handler-names */
import React, { useCallback, useEffect, useState } from 'react'
import Card from 'react-bootstrap/Card'
import StaticSelectInput from '../common/StaticSelectInput'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import PropTypes from 'prop-types'
import {
  Trash,
  PencilSquare,
  Check2Circle
} from 'react-bootstrap-icons'
import { toast } from 'react-toastify'

import MoveRunForm from './MoveRunForm'
import EditAddressModal from './EditAddressModal'
import EditNotesModal from './EditNotesModal'

import { PUBLIC_RUNS_URL, RUN_PICTURE_DELETE_URL, RUN_PICTURE_LIST_URL, RUN_PICTURE_UPLOAD_URL } from '../api_urls'
import DeleteRecurringModal from './DeleteRecurringModal'
import { MGE_CONTAINER_NUM, MGE_LOCATION_ID, NO_CONTAINER_NAME } from '../../globals'
import { compareContainerNumbers, filterOption } from '../util'
import { DateTime } from 'luxon'
import configuredAxios from '../../configured-axios'
import ViewPicturesModal from './ViewPicturesModal'
import AddPictureModal from './AddPictureModal'

export default function RunCard(props) {
  const runId = props.runId

  const [run, setRun] = useState({})
  const [runPictureFilenames, setRunPictureFilenames] = useState([])
  const [refresh, setRefresh] = useState(false)

  const [showAddressModal, setShowAddressModal] = useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)
  const [showRecurringModal, setShowRecurringModal] = useState(false)
  const [showViewPicturesModal, setShowViewPicturesModal] = useState(false)
  const [showAddPictureModal, setShowAddPictureModal] = useState(false)
  const [deactivate, setDeactivate] = useState(false)

  const [availablePickUp, setAvailablePickup] = useState([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }])
  const [availableDropOff, setAvailableDropOff] = useState([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }])

  const handleAddressModalClose = () => setShowAddressModal(false)
  const handleAddressModalShow = () => setShowAddressModal(true)
  const handleNotesModalClose = () => setShowNotesModal(false)
  const handleNotesModalShow = () => setShowNotesModal(true)
  const handleRecurringModalShow = () => setShowRecurringModal(true)
  const handleRecurringModalClose = () => setShowRecurringModal(false)

  const CONST_MAX_HISTORY_SEC = 60

  const handlePickUpChange = (name, value, label) => {
    const editRun = async (_run) => {
      try {
        const URL = PUBLIC_RUNS_URL + '/' + _run.id
        await configuredAxios.put(URL, _run)
        const updatedRun = { ...run }
        if (run.dropOffId !== MGE_CONTAINER_NUM && run.pickUpId === run.dropOffId && changesToRun.pickUpId !== run.dropOffId) {
          updatedRun.dropOff = null
          updatedRun.dropOffId = null
        }
        updatedRun.pickUp = value ? { id: value, number: label } : null
        updatedRun.pickUpId = value || null
        setRun(updatedRun)

        setRefresh(true)
        toast.success('Updated Run Successfully')
      } catch (error) {
        toast.error('Run could not be updated')
        setRun(props.run)
      }
    }

    const changesToRun = {
      id: run.id,
      pickUpId: value || null
    }

    if (run.dropOffId !== MGE_CONTAINER_NUM && run.pickUpId === run.dropOffId && value !== run.dropOffId) {
      changesToRun.dropOffId = null
    }
    editRun(changesToRun)
  }

  const handleDropOffChange = (name, value, label) => {
    const editRun = async (_run) => {
      try {
        const URL = PUBLIC_RUNS_URL + '/' + _run.id
        await configuredAxios.put(URL, _run)
        const updatedRun = { ...run }

        updatedRun.dropOff = value ? { id: value, number: label } : null
        updatedRun.dropOffId = value || null
        setRun(updatedRun)

        setRefresh(true)
        toast.success('Updated Run Successfully')
      } catch (error) {
        toast.error('Run could not be updated')
        setRun(props.run)
      }
    }

    const changesToRun = {
      id: run.id,
      dropOffId: value || null
    }
    editRun(changesToRun)
  }

  function getSecDiff(dateA, dateB) {
    const da = DateTime.fromISO(dateA)
    const db = DateTime.fromISO(dateB)

    return Math.abs(da.diff(db, 'seconds').toObject().seconds)
  }

  function handleImageUpload(formData) {
    configuredAxios.post(RUN_PICTURE_UPLOAD_URL, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(() => {
      toast.success('Successfully uploaded run picture.')
      setShowAddPictureModal(false)
      fetchPictureFilenames()
    })
  }

  const fetchPictureFilenames = useCallback(() => {
    const PICTURES_URL = RUN_PICTURE_LIST_URL + runId
    configuredAxios.get(PICTURES_URL).then((res) => {
      setRunPictureFilenames(res.data)
    }).catch((err) => {
      console.log(err)
      toast.error('Could not find pictures for run ' + runId)
    })
  }, [runId])

  useEffect(() => {
    const URL = PUBLIC_RUNS_URL + '/' + runId
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(URL)
        setRun(response.data)
      } catch (error) {
        toast.error('Could not find run for id ' + runId)
        setRun({})
      }
    }
    fetchData()
    fetchPictureFilenames()
  }, [runId, refresh, fetchPictureFilenames])

  function handleOpenViewPicturesModal() {
    fetchPictureFilenames()
    setShowViewPicturesModal(true)
  }

  function handlePictureDelete(filename) {
    configuredAxios.delete(`${RUN_PICTURE_DELETE_URL}/${runId}?filename=${filename}`).then(() => {
      toast.success('Successfully deleted run picture.')
      fetchPictureFilenames()
    }).catch((err) => {
      console.log(err)
      toast.error('An error occurred when deleting the run picture.')
    })
  }

  useEffect(() => {
    let availPickup = []
    let availDropoff = []

    if (run && props.containers.length > 0) {
      availPickup = props.containers?.reduce((acc, c) => {
        if (c.histories[0]?.locationId === run.locationId && c.id !== MGE_CONTAINER_NUM) {
          acc.push({ id: c.id, number: c.number })
        }
        return acc
      }, [])

      availDropoff = props.containers?.reduce((acc, c) => {
        if (c.histories[0]?.locationId === MGE_LOCATION_ID && c.id !== MGE_CONTAINER_NUM) {
          acc.push({ id: c.id, number: c.number })
        }
        return acc
      }, [])
    }

    setAvailablePickup([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }].concat(availPickup))
    setAvailableDropOff([{ id: MGE_CONTAINER_NUM, number: NO_CONTAINER_NAME }].concat(availDropoff))
  }, [run, props.containers])

  if (Object.keys(run).length <= 0) {
    return (
      <Card className='run-card'>
        <Card.Title>Run Id: {runId}</Card.Title>
        <Card.Title>No information</Card.Title>
      </Card>
    )
  } else {
    let canReactivate = false
    if (run.complete) {
      const pContainer = props.containers.find((c) => c.id === run.pickUpId)
      const dContainer = props.containers.find((c) => c.id === run.dropOffId)

      if (pContainer && dContainer) {
        if (run.pickUpId === run.dropOffId) {
          // Same container. Customer --> MGE --> customer
          if (run.pickUpId === MGE_CONTAINER_NUM) {
            // No containers were picked up or dropped off.  This run can always be reactivated
            canReactivate = true
          } else if (dContainer.histories[0].locationId === run.locationId &&
            dContainer.histories[1].locationId === MGE_LOCATION_ID &&
            dContainer.histories[2].locationId === run.locationId) {
            if (getSecDiff(dContainer.histories[0].dropOff, run.updatedAt) < CONST_MAX_HISTORY_SEC &&
              getSecDiff(dContainer.histories[1].pickUp, run.updatedAt) < CONST_MAX_HISTORY_SEC &&
              getSecDiff(dContainer.histories[2].pickUp, run.updatedAt) < CONST_MAX_HISTORY_SEC) {
              canReactivate = true
            }
          }
        } else if (pContainer.id === MGE_CONTAINER_NUM) {
          // Dropoff only. MGE --> customer
          if (dContainer.histories[0].locationId === run.locationId &&
            dContainer.histories[1].locationId === MGE_LOCATION_ID) {
            if (getSecDiff(dContainer.histories[0].dropOff, run.updatedAt) < CONST_MAX_HISTORY_SEC &&
              getSecDiff(dContainer.histories[1].pickUp, run.updatedAt) < CONST_MAX_HISTORY_SEC) {
              canReactivate = true
            }
          }
        } else if (dContainer.id === MGE_CONTAINER_NUM) {
          // Pickup only. Customer --> MGE
          if (pContainer.histories[0].locationId === MGE_LOCATION_ID &&
            pContainer.histories[1].locationId === run.locationId) {
            if (getSecDiff(pContainer.histories[0].dropOff, run.updatedAt) < CONST_MAX_HISTORY_SEC &&
              getSecDiff(pContainer.histories[1].pickUp, run.updatedAt) < CONST_MAX_HISTORY_SEC) {
              canReactivate = true
            }
          }
        } else {
          // Pickup and dropoff. Pickup: customer --> MGE. Dropoff: MGE --> customer
          if (dContainer.histories[0].locationId === run.locationId &&
            dContainer.histories[1].locationId === MGE_LOCATION_ID &&
            pContainer.histories[0].locationId === MGE_LOCATION_ID &&
            pContainer.histories[1].locationId === run.locationId) {
            if (getSecDiff(pContainer.histories[0].dropOff, run.updatedAt) < CONST_MAX_HISTORY_SEC &&
                getSecDiff(pContainer.histories[1].pickUp, run.updatedAt) < CONST_MAX_HISTORY_SEC &&
                getSecDiff(dContainer.histories[0].dropOff, run.updatedAt) < CONST_MAX_HISTORY_SEC &&
                getSecDiff(dContainer.histories[1].pickUp, run.updatedAt) < CONST_MAX_HISTORY_SEC
            ) {
              canReactivate = true
            }
          }
        }
      }
    }

    let picturesButtonDisplayStyle = {}
    if (runPictureFilenames.length === 0) {
      picturesButtonDisplayStyle = {
        display: 'none'
      }
    }

    return (
      <Card className='run-card'>
        <Card.Body
          className={run.complete === true ? 'card-completed' : ''}
        >
          {run.complete && (
            <Card.Title className='check-completed'>
              Run Completed{' '}
              <Check2Circle className='completed-checkmark' />
            </Card.Title>
          )}
          <Card.Title>Run Id: {runId}</Card.Title>
          {run.location
            ? (
              <Card.Title>{run.location.customer.name}</Card.Title>
            )
            : (
              <Card.Title>No Location Found</Card.Title>
            )}
          {props.authenticated && run.location
            ? (
              <>
                <p onClick={handleAddressModalShow}>
                  <PencilSquare className='edit-icon' /> Edit Address
                </p>
                <EditAddressModal
                  show={showAddressModal}
                  handleClose={handleAddressModalClose}
                  location={run.location}
                  handleRefresh={setRefresh}
                  locationId={run.locationId}
                />
              </>
            )
            : (
              ''
            )}
          {run.location && (
            <>
              <Card.Subtitle className='mb-2'>{`${run.location.address} ${run.location.city}, ${run.location.state} ${run.location.zip}`}</Card.Subtitle>
              <Card.Subtitle className='mb-2'>{`${run.location.name} ${run.location.phone}`}</Card.Subtitle>
            </>
          )}
        </Card.Body>
        <ListGroup variant='flush'>
          <ListGroup.Item
            className={run.complete === true ? 'card-completed' : ''}
          >
            <Card.Title>Notes</Card.Title>
            <>
              <p onClick={handleNotesModalShow}>
                <PencilSquare className='edit-icon' /> Edit Note
              </p>
              <EditNotesModal
                show={showNotesModal}
                handleClose={handleNotesModalClose}
                note={run.description}
                handleRefresh={setRefresh}
                runId={run.id}
              />
            </>
            <Card.Body>
              <Card.Text>{run.description}</Card.Text>
              <Button className='mr-2' onClick={() => { setShowAddPictureModal(true) }}>Add Picture</Button>
              <AddPictureModal
                show={showAddPictureModal}
                onHide={() => { setShowAddPictureModal(false) }}
                onSubmit={(formData) => { handleImageUpload(formData) }}
                runId={runId}
              />
              <Button onClick={() => { handleOpenViewPicturesModal() }} style={picturesButtonDisplayStyle}>View Pictures</Button>
              <ViewPicturesModal
                show={showViewPicturesModal}
                onHide={() => { setShowViewPicturesModal(false) }}
                runId={runId}
                runPictureFilenames={runPictureFilenames}
                onPictureDelete={(filename) => { handlePictureDelete(filename) }}
                authenticated={props.authenticated}
              />
            </Card.Body>
          </ListGroup.Item>
          <ListGroup.Item
            className={run.complete === true ? 'card-completed' : ''}
          >
            <Card.Body>
              <StaticSelectInput
                collection={availablePickUp}
                label='Pick Up: '
                name='pickUpId'
                value={run.pickUp ? { value: run.pickUp.id, label: run.pickUp.number === 0 ? NO_CONTAINER_NAME : run.pickUp.number } : null}
                handleChange={handlePickUpChange}
                select_label='number'
                filterOption={filterOption}
                isClearable
                isDisabled={run.complete}
              />
            </Card.Body>
            <Card.Body>
              <StaticSelectInput
                collection={run.pickUp && run.pickUpId !== MGE_CONTAINER_NUM ? availableDropOff.concat({ id: run.pickUp.id, number: run.pickUp.number }).sort(compareContainerNumbers) : availableDropOff}
                label='Drop Off: '
                name='dropOffId'
                value={run.dropOff ? { value: run.dropOff.id, label: run.dropOff.number === 0 ? NO_CONTAINER_NAME : run.dropOff.number } : null}
                handleChange={handleDropOffChange}
                select_label='number'
                filterOption={filterOption}
                isClearable
                isDisabled={run.complete}
              />
              {run.dropOff === null || run.pickUp === null
                ? (
                  <span />
                )
                : run.complete === true
                  ? (
                    <Button
                      onClick={() => {
                        setDeactivate(true)
                        props.markIncomplete(props.id)
                      }}
                      variant='secondary'
                      disabled={deactivate || !canReactivate}
                    >
                      Reactivate
                    </Button>
                  )
                  : (
                    <Button
                      onClick={() => {
                        setDeactivate(true)
                        props.markComplete(props.id)
                      }}
                      variant='primary'
                      disabled={deactivate}
                    >
                      Complete
                    </Button>
                  )}

              {props.authenticated && run.recurrenceId && !run.complete
                ? (
                  <>
                    <Trash
                      className='card-trash'
                      onClick={handleRecurringModalShow}
                    />
                    <DeleteRecurringModal
                      show={showRecurringModal}
                      handleClose={handleRecurringModalClose}
                      handleDestroy={props.handleDestroy}
                      handleDestroySeries={props.handleDestroySeries}
                      id={props.id}
                      recurrenceId={run.recurrenceId}
                    />
                  </>
                )
                : props.authenticated && !run.recurrenceId && !run.complete
                  ? (
                    <Trash
                      className='card-trash'
                      onClick={() => props.handleDestroy(props.id)}
                    />
                  )
                  : (
                    <span />
                  )}
            </Card.Body>
          </ListGroup.Item>
          {props.authenticated && (
            <ListGroup.Item
              className={
                run.complete === true ? 'card-completed' : ''
              }
            >
              <Card.Title>Change Daily Run</Card.Title>
              <Card.Body>
                <MoveRunForm {...props} {...run} />
              </Card.Body>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card>
    )
  }
}

RunCard.propTypes = {
  id: PropTypes.any,
  run: PropTypes.object,
  runId: PropTypes.any,
  containers: PropTypes.array,
  authenticated: PropTypes.bool,
  markIncomplete: PropTypes.func,
  handleDestroy: PropTypes.func,
  handleDestroySeries: PropTypes.func,
  markComplete: PropTypes.func
}
