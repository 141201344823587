import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import { toast } from 'react-toastify'
import Swal from 'sweetalert2'

import HistoryList from '../history/HistoryList'
import RepairList from '../repairs/RepairsList'
import { CONTAINERS_URL } from '../api_urls'
import ContainerEditModal from './ContainerEditModal'
import { PencilSquare } from 'react-bootstrap-icons'
import configuredAxios from '../../configured-axios'

function ContainerBox(props) {
  const [containerBox, setContainerBox] = useState()
  const [refresh, setRefresh] = useState()
  const [showModal, setShowModal] = useState(false)
  const { id } = useParams()

  const handleModalClose = () => setShowModal(false)
  const handleModalShow = () => setShowModal(true)

  useEffect(() => {
    const fetchData = async () => {
      Swal.fire({
        text: 'Loading...',
        icon: 'info',
        allowEnterKey: false,
        allowEscapeKey: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        backdrop: 'rgba(225, 225, 225, 1)'
      })
      try {
        const response = await configuredAxios.get(CONTAINERS_URL + '/' + id)
        setContainerBox(response.data)
      } catch (error) {
        toast.error('Could not retrieve Container')
      }
      Swal.close()
    }
    fetchData()
    setRefresh(false)
  }, [id, refresh, setRefresh])

  if (containerBox) {
    return (
      <Container>
        <h2>Container Detail</h2>
        {containerBox && (
          <Container>
            <Table borderless>
              <tbody>
                <tr>
                  <td>
                    {props.authenticated && (
                      <>
                        <p onClick={handleModalShow}>
                          <PencilSquare className='edit-icon' /> Edit
                          Container
                        </p>
                        <ContainerEditModal
                          show={showModal}
                          handleClose={handleModalClose}
                          containerBox={containerBox}
                          handleRefresh={setRefresh}
                          containerId={containerBox.id}
                        />
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>Number:</td>
                  <td>{containerBox.number}</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>Size:</td>
                  <td>{containerBox.size}</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  <td>Description:</td>
                  <td>{containerBox.description}</td>
                  <td />
                  <td />
                </tr>
                <tr>
                  {containerBox.histories.length > 0 &&
                    containerBox.histories[0].location && (
                      <>
                      <td>Current Location:</td>
                      <td>
                          <b>
                          {
                            containerBox.histories[0].location
                              .customer.name
                          }
                          :
                        </b>
                          &nbsp;&nbsp;
                          <i>
                          {
                            containerBox.histories[0].location
                              .address
                          }
                        </i>
                        </td>
                      <td />
                      <td />
                    </>
                  )}
                </tr>
              </tbody>
            </Table>
            <HistoryList
              histories={containerBox.histories}
              handleRefresh={setRefresh}
              containerBoxId={containerBox.id}
            />
            <RepairList
              repairs={containerBox.repairs}
              handleRefresh={setRefresh}
              containerBoxId={containerBox.id}
            />
          </Container>
        )}
      </Container>
    )
  } else {
    return <div />
  }
}

export default ContainerBox
