import React from 'react'

export default function NavArea() {
  return (
    <div className='custom-nav-bar'>
      <a href='/' tabIndex='0'>
        Home
      </a>
      <a href='/admin' tabIndex='0'>
        Administrator Settings
      </a>
      <a href='/run' tabIndex='0'>
        Daily Runs
      </a>
      <a href='/trucking-list' tabIndex='0'>
        Trucking List
      </a>
      <a href='/customer' tabIndex='0'>
        Customers
      </a>
      <a href='/container_box' tabIndex='0'>
        Containers
      </a>
      <a href='/truck' tabIndex='0'>
        Trucks
      </a>
      <a href='/truck-code' tabIndex='0'>
        Truck Code
      </a>
    </div>
  )
}
