import React, { useState, useEffect, useContext } from 'react'
import Container from 'react-bootstrap/Container'
import { toast } from 'react-toastify'

import AlertList from './alerts/AlertList'
import AlertContext from './context/AlertContext'
import { ALERTS_URL, UNREAD_ALERTS_URL } from './api_urls'
import configuredAxios from '../configured-axios'

function alertsWithId(id) {
  return ALERTS_URL + '/' + id
}

function AlertPage(props) {
  const [alerts, setAlerts] = useState([])
  const [refresh, setRefresh] = useState(false)
  const { setUnreadAlerts } = useContext(AlertContext)

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await configuredAxios.get(ALERTS_URL)
        setAlerts(response.data)
        response = await configuredAxios.get(UNREAD_ALERTS_URL)
        setUnreadAlerts(response.data)
      } catch (error) {
        toast.error('Could not retrieve Alerts')
      }
    }
    fetchData()
    setRefresh(false)
  }, [refresh, setUnreadAlerts])

  async function markRead(id) {
    const putAlert = async (_id) => {
      try {
        await configuredAxios.put(alertsWithId(_id), { read: true, id: _id })
        setRefresh(true)
      } catch (error) {
        toast.error('Could not mark message as read')
      }
    }
    putAlert(id)
  }

  async function handleDestroy(id) {
    const putAlert = async (_id) => {
      try {
        await configuredAxios.delete(alertsWithId(_id))
        setRefresh(true)
        toast.success('Deleted Message: ', _id)
      } catch (error) {
        toast.error('Could not delete message')
      }
    }
    putAlert(id)
  }

  return (
    <Container>
      <h2>Alerts</h2>
      <AlertList
        alerts={alerts}
        markRead={markRead}
        handleDestroy={handleDestroy}
      />
    </Container>
  )
}

export default AlertPage
