import React from 'react'
import Form from 'react-bootstrap/Form'

import TextInput from '../common/TextInput'
import StateSelect from '../common/StateSelect'
import PropTypes from 'prop-types'
import { shallowClone } from '../../helper'

function LocationForm({ location, locationErrors = {}, onChange = () => {} }) {
  function handleChange(event) {
    const newLocation = shallowClone(location)
    newLocation[event.target.name] = event.target.value
    onChange(newLocation)
  }

  return (
    <Form id='location-form'>
      <Form.Row>
        <TextInput
          label='Nickname'
          name='nickname'
          value={location.nickname}
          onChange={(evt) => { handleChange(evt) }}
          error={locationErrors.nickname}
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          label='Address'
          name='address'
          value={location.address}
          onChange={(evt) => { handleChange(evt) }}
          error={locationErrors.address}
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          label='City'
          name='city'
          value={location.city}
          onChange={(evt) => { handleChange(evt) }}
          error={locationErrors.city}
        />
        <StateSelect
          value={location.state}
          onChange={(evt) => { handleChange(evt) }}
        />
        <TextInput
          label='Zip'
          name='zip'
          value={location.zip}
          onChange={(evt) => { handleChange(evt) }}
          error={locationErrors.zip}
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          label='Name'
          name='name'
          value={location.name}
          onChange={(evt) => { handleChange(evt) }}
          error={locationErrors.name}
        />
        <TextInput
          label='Phone'
          name='phone'
          value={location.phone}
          onChange={(evt) => { handleChange(evt) }}
          error={locationErrors.phone}
        />
      </Form.Row>
    </Form>
  )
}

LocationForm.propTypes = {
  location: PropTypes.object,
  locationErrors: PropTypes.object,
  onChange: PropTypes.func
}

export default LocationForm
