import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Person, ChatLeftText } from "react-bootstrap-icons";

import UnreadAlerts from "./alerts/UnreadAlerts";

export default function Header() {
  return (
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="/">
        <span className="LogoInitial">M</span>ark
        <span className="LogoInitial">G</span>ray
        <span className="LogoInitial">E</span>nterprises
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text>
          <a href="/login">
            <Person size={30} />
          </a>
          <a href="/alerts">
            <ChatLeftText size={20} />
            <UnreadAlerts />
          </a>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}
