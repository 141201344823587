import React from 'react'
import Select from 'react-select'

import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import PropTypes from 'prop-types'

function StaticSelectInput(props) {
  function mapOptions() {
    return props.collection.map((c) => {
      return { value: c.id, label: c[props.select_label] }
    })
  }

  return (
    <Form.Group
      className='data-select-group'
      as={Row}
      controlId={'formGrid' + props.name}
    >
      <Form.Label className='data-select-label'>{props.label}</Form.Label>
      <Select
        options={mapOptions()}
        defaultValue={props.defaultValue}
        value={props.value}
        onChange={(res) => {
          props.handleChange(props.name, res?.value, res?.label)
        }}
        className='basic-single'
        filterOption={props.filterOption}
        isClearable={props.isClearable}
        isDisabled={props.isDisabled}
      />
    </Form.Group>
  )
}

StaticSelectInput.propTypes = {
  isDisabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  collection: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  select_label: PropTypes.any,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  filterOption: PropTypes.func,
  handleChange: PropTypes.func
}

export default StaticSelectInput
