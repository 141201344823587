import React from 'react'
import Table from 'react-bootstrap/Table'
import { Trash, PencilSquare } from 'react-bootstrap-icons'
import { useHistory } from 'react-router-dom'

import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

function LocationTableRow({ customerId, location, onDeleteLocation, onEditLocation }) {
  const history = useHistory()

  return (
    <tr>
      <td>{location.nickname}</td>
      <td>{location.address}</td>
      <td>{location.city}</td>
      <td>{location.state}</td>
      <td>{location.zip}</td>
      <td>{location.name}</td>
      <td>{location.phone}</td>
      <td>
        <Button onClick={() => { history.push(`/location/${location.id}`) }}>Details</Button>
      </td>
      <td>
        <PencilSquare onClick={() => onEditLocation(location)} />
      </td>
      <td>
        <Trash onClick={() => { onDeleteLocation(location) }} />
      </td>
    </tr>
  )
}

function sortLocations(locations) {
  locations.sort((a, b) => {
    const aNickName = a.nickname?.toLowerCase() || ''
    const bNickName = b.nickname?.toLowerCase() || ''
    return aNickName.localeCompare(bNickName)
  })
  return locations
}

LocationTableRow.propTypes = {
  customerId: PropTypes.number,
  location: PropTypes.object,
  onDeleteLocation: PropTypes.func,
  onEditLocation: PropTypes.func
}

function LocationList({ customerId, locations, onDeleteLocation, onEditLocation, sort = true }) {
  if (sort) {
    sortLocations(locations)
  }

  const locationRows = []
  for (const location of locations) {
    const row = (
      <LocationTableRow
        customerId={customerId}
        location={location}
        key={location.id}
        onEditLocation={onEditLocation}
        onDeleteLocation={onDeleteLocation}
      />)
    locationRows.push(row)
  }

  let noLocationsFoundRow = (<></>)
  if (locationRows.length === 0) {
    noLocationsFoundRow = (
      <tr>
        <td colSpan='9'> No Locations Found</td>
      </tr>
    )
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Nickname</th>
          <th>Address</th>
          <th>City</th>
          <th>State</th>
          <th>Zip</th>
          <th>Name</th>
          <th>Phone</th>
          <th />
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {locationRows}
        {noLocationsFoundRow}
      </tbody>
    </Table>
  )
}

LocationList.propTypes = {
  customerId: PropTypes.number,
  locations: PropTypes.object,
  onDeleteLocation: PropTypes.func,
  onEditLocation: PropTypes.func,
  sort: PropTypes.bool
}

export default LocationList
