import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import { toast } from 'react-toastify'
import { SIMPLE_TRUCKS_URL, TRUCKS_URL } from './api_urls'

import TruckList from './trucks/TruckList'
import TruckForm from './trucks/TruckForm'
import configuredAxios from '../configured-axios'

function TruckPage() {
  const empty_truck = {
    number: '',
    description: '',
    active: true
  }

  const [truck, setTruck] = useState(empty_truck)
  const [trucks, setTrucks] = useState([])
  const [refresh, setRefresh] = useState([])
  const [errors, setErrors] = useState({})

  function validForm() {
    const _errors = {}

    if (!truck.number) _errors.number = 'Number is required'
    fetchTrucks()
    if (trucks.some(el => { return el.number === truck.number })) _errors.number = 'Number already exists'

    setErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  async function fetchTrucks() {
    try {
      const response = await configuredAxios.get(SIMPLE_TRUCKS_URL)
      setTrucks(response.data)
    } catch (error) {
      toast.error('Could not retrieve Trucks')
      setTrucks([])
    }
  }

  function handleChange({ target }) {
    let val = ''
    if (target.name === 'active') {
      val = !!target.checked
    } else {
      val = target.value
    }
    setTruck({
      ...truck,
      [target.name]: val
    })
  }

  async function handleSubmit(evt) {
    evt.preventDefault()
    if (!validForm()) return

    const postTruck = async (c) => {
      try {
        await configuredAxios.post(TRUCKS_URL, c)
        setTruck(empty_truck)
        setRefresh(true)
        toast.success('Truck Created Successfully')
      } catch (error) {
        toast.error('Truck could not be created')
      }
    }
    postTruck(truck)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(SIMPLE_TRUCKS_URL)
        setTrucks(response.data)
      } catch (error) {
        toast.error('Could not retrieve Trucks')
        setTrucks([])
      }
    }
    fetchData()
    setRefresh(false)
  }, [refresh])

  return (
    <Container>
      <h2>Trucks</h2>
      <Container>
        <TruckForm
          truck={truck}
          onChange={handleChange}
          onSubmit={handleSubmit}
          errors={errors}
        />
        <TruckList trucks={trucks} handleRefresh={setRefresh} />
      </Container>
    </Container>
  )
}

export default TruckPage
