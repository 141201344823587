import React, { useEffect, useState } from 'react'
import { Plus } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import RunForm from './RunForm'
import RunCard from './RunCard'
import CardDeck from 'react-bootstrap/CardDeck'
import { SIMPLE_CONTAINERS_URL, SIMPLE_DAILY_RUNS_URL } from '../api_urls'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import configuredAxios from '../../configured-axios'

function Rows(props) {
  if (props.runs && props.runs.length > 0) {
    return props.runs.map((run) => {
      return (
        <RunCard
          {...run}
          runId={run.id}
          key={run.id}
          markComplete={props.markComplete}
          authenticated={props.authenticated}
          handleDestroy={props.handleDestroy}
          handleDestroySeries={props.handleDestroySeries}
          handleRefresh={props.handleRefresh}
          containers={props.containers}
          dailyRuns={props.dailyRuns}
          markIncomplete={props.markIncomplete}
        />
      )
    })
  } else {
    return <span>No Runs Found</span>
  }
}

function RunList(props) {
  const [showForm, setShowForm] = useState(false)
  const [containers, setContainers] = useState([])
  const [dailyRuns, setDailyRuns] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [weekdayCnt, setWeekdayCnt] = useState({})

  function getWeekdayCount() {
    const currentDate = DateTime.local().setZone('America/New_York')
    const daysInMonth = currentDate.daysInMonth

    const { year, month } = currentDate.c

    let i = 1
    const weekdayCount = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0
    }

    while (i <= daysInMonth) {
      if (DateTime.local(year, month, i).weekday !== 6 && DateTime.local(year, month, i).weekday !== 7) {
        switch (DateTime.local(year, month, i).weekday) {
          case 1:
            weekdayCount.monday++
            break
          case 2:
            weekdayCount.tuesday++
            break
          case 3:
            weekdayCount.wednesday++
            break
          case 4:
            weekdayCount.thursday++
            break
          case 5:
            weekdayCount.friday++
            break
          default:
            break
        }
      }
      i++
    }
    setWeekdayCnt(weekdayCount)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(SIMPLE_CONTAINERS_URL)
        setContainers(response.data)
      } catch (error) {
        toast.error('Could not retrieve containers.')
        setContainers([])
      }
    }
    fetchData()
    setRefresh(false)
  }, [refresh])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(SIMPLE_DAILY_RUNS_URL)
        setDailyRuns(response.data)
      } catch (error) {
        toast.error('Could not retrieve daily runs.')
        setDailyRuns([])
      }
    }
    fetchData()
    getWeekdayCount()
    setRefresh(false)
  }, [refresh])

  return (
    <Container>
      <h4>
        Runs&nbsp;
        <Plus onClick={() => setShowForm(!showForm)} />
      </h4>
      {showForm && (
        <RunForm
          customerId={props.customerId}
          handleRefresh={props.handleRefresh}
          handleShowForm={setShowForm}
          dailyRunId={props.dailyRunId}
          weekdayCount={weekdayCnt}
          containers={containers}
        />
      )}
      <CardDeck>
        <Rows
          runs={props.runs}
          markComplete={props.markComplete}
          authenticated={props.authenticated}
          handleDestroy={props.handleDestroy}
          handleDestroySeries={props.handleDestroySeries}
          handleRefresh={props.handleRefresh}
          containers={containers}
          dailyRuns={dailyRuns}
          markIncomplete={props.markIncomplete}
        />
      </CardDeck>
    </Container>
  )
}

export default RunList
