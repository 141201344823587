import React, { useState } from 'react'
import { Container, Form, Image, Modal, Row, Col, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'

const AddPictureModal = ({ show, onHide, onSubmit, runId }) => {
  const [imagePreviewData, setImagePreviewData] = useState('')
  const [file, setFile] = useState(null)

  function handleInputChange(evt) {
    const files = evt.target.files[0]
    setFile(files)
    if (files) {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(files)
      fileReader.addEventListener('load', function () {
        setImagePreviewData(this.result)
      })
    }
  }

  function handleImageFormSubmit(evt) {
    evt.preventDefault()
    const formData = new FormData()

    // These must be appended in this order, runId and then runPicture
    formData.append('runId', runId)
    formData.append('runPicture', file)
    onSubmit(formData)
    setFile(null)
    setImagePreviewData('')
  }

  let imagePreview = (<></>)
  if (imagePreviewData) {
    imagePreview = (
      <>
        <p className='mt-3'>Preview</p>
        <Container className='border p-3 mb-3'>
          <Row>
            <Col>
              <Image fluid src={imagePreviewData} alt='' />
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  return (
    <Modal size='lg' show={show} onHide={onHide}>
      <Modal.Header>Upload a Run Picture</Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleImageFormSubmit}>
          <Form.Label>Choose a file</Form.Label>
          <Form.Control type='file' accept='image/*' onChange={handleInputChange} name='runPicture' />
          {imagePreview}
          <Button type='submit' className='float-right' disabled={imagePreviewData === ''}>Upload</Button>
          <Button variant='secondary' onClick={() => { onHide() }} className='float-right mr-2'>Close</Button>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

AddPictureModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  runId: PropTypes.number
}

export default AddPictureModal
