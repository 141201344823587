import React, { useEffect } from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'

function Search({ searchTerm, onChange, onSearch, type = 'text', placeholder = 'Search...', name = 'search' }) {
  useEffect(() => {
    const delayQuery = setTimeout(() => {
      onSearch()
    }, 580)
    return () => clearTimeout(delayQuery)
  }, [onSearch, searchTerm])

  return (
    <Form.Control
      type={type}
      placeholder={placeholder}
      name={name}
      onChange={e => { onChange(e.target.value) }}
    />
  )
}

Search.propTypes = {
  searchTerm: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string
}

export default Search
