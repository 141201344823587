import React from 'react'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'

import { dmyString } from '../../helper'

function DailyRunTableRow(props) {
  const history = useHistory()

  const truckNumber = props.truck ? props.truck.number : ''
  const truckDescription = props.truck ? props.truck.description : ''

  return (
    <tr onClick={() => history.push('/run/' + props.id)}>
      <td>{dmyString(props.date)}</td>
      <td>{props.code}</td>
      <td>{truckNumber}</td>
      <td>{truckDescription}</td>
    </tr>
  )
}

function Rows(props) {
  if (props.dailyRuns && props.dailyRuns.length > 0) {
    return props.dailyRuns.map((dailyRun) => {
      return <DailyRunTableRow {...dailyRun} key={dailyRun.id} />
    })
  } else {
    return (
      <tr>
        <td colSpan='5'> No Daily Runs Found</td>
      </tr>
    )
  }
}

export default function DailyRunList(props) {
  return (
    <Table bordered hover className='collection-table'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Code</th>
          <th>Truck Number</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <Rows dailyRuns={props.dailyRuns} />
      </tbody>
    </Table>
  )
}
