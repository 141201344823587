import React from "react";
import Table from "react-bootstrap/Table";
import { useHistory } from "react-router-dom";

function TruckTableRow(props) {
  let history = useHistory();
  return (
    <tr
      className={props.active ? "" : "table-active"}
      onClick={() => history.push("/truck/" + props.id)}
    >
      <td>{props.number}</td>
      <td>{props.description}</td>
      <td>{props.active ? "Active" : "Inactive"}</td>
    </tr>
  );
}

function Rows(props) {
  if (props.trucks && props.trucks.length > 0) {
    return props.trucks.map((truck) => {
      return <TruckTableRow {...truck} key={truck.id} />;
    });
  } else {
    return (
      <tr>
        <td colSpan="2">No Trucks Found</td>
      </tr>
    );
  }
}

export default function TruckList(props) {
  return (
    <Table bordered hover className="collection-table">
      <thead>
        <tr>
          <th>Number</th>
          <th>Description</th>
          <th>Active</th>
        </tr>
      </thead>
      <tbody>
        <Rows trucks={props.trucks} />
      </tbody>
    </Table>
  );
}
