import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getHighlightedText } from '../../textHelpers'

function CustomerDisplay({ customer, highlightedSearchTerm = '' }) {
  const history = useHistory()

  const locationRows = []
  for (const location of customer.customer_locations) {
    let nicknameText = ''
    if (location.nickname) {
      nicknameText = `${location.nickname}: `
    }
    let nameText = ''
    if (location.name) {
      nameText = ` | ${location.name}`
    }
    let phoneText = ''
    if (location.phone) {
      phoneText = ` | ${location.phone}`
    }
    const locationText = `${nicknameText}${location.address || ''}, ${location.city || ''}, ${location.state || ''} ${location.zip || ''}${nameText}${phoneText}`
    let locationTextWithHighlight = (<span>{locationText}</span>)
    if (highlightedSearchTerm) {
      locationTextWithHighlight = getHighlightedText(locationText, highlightedSearchTerm)
    }

    locationRows.push(
      <Row key={location.id}>
        <Col>{locationTextWithHighlight}</Col>
      </Row>
    )
  }
  let customerName = (<span>{customer.customer_name}</span>)
  if (highlightedSearchTerm) {
    customerName = getHighlightedText(customer.customer_name, highlightedSearchTerm)
  }
  let customerNumber = (<span>{customer.customer_number}</span>)
  if (highlightedSearchTerm) {
    customerNumber = getHighlightedText(customer.customer_number, highlightedSearchTerm)
  }

  return (
    <Container className='border mb-1 mt-1 rounded'>
      <Row className='align-items-center flex-nowrap justify-content-md-between'>
        <Col>
          <Container className='p-0 mb-2 mt-2'>
            <Row className='no-gutters'>
              <Col>
                <span><b>Name:</b> {customerName}</span>
              </Col>
              <Col>
                <span><b>Number:</b> {customerNumber}</span>
              </Col>
            </Row>
            <Row className='no-gutters'>
              <Col>
                <span><b>Locations:</b></span>
              </Col>
            </Row>
            {locationRows}
          </Container>
        </Col>
        <Col xs={2}>
          <Button className='float-right' onClick={() => history.push('/customer/' + customer.customer_id)}>Edit</Button>
        </Col>
      </Row>
    </Container>
  )
}
CustomerDisplay.propTypes = {
  customer: PropTypes.object,
  highlightedSearchTerm: PropTypes.string
}

export default function CustomerList(props) {
  const customerRows = []

  for (const customer of props.customers) {
    customerRows.push(
      <Row key={customer.customer_id}>
        <CustomerDisplay highlightedSearchTerm={props.highlightedSearchTerm} customer={customer} />
      </Row>
    )
  }

  if (customerRows.length === 0) {
    customerRows.push(
      <Row>
        No Customers Found
      </Row>
    )
  }

  return (
    <Container>
      {customerRows}
    </Container>
  )
}
CustomerList.propTypes = {
  customers: PropTypes.array,
  highlightedSearchTerm: PropTypes.string
}
