import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TextInput from '../common/TextInput'
import Checkbox from '../common/Checkbox'
import ColorSelection from '../common/ColorSelection'

function JobTypeForm(props){
  return (
    <Form onSubmit={props.onSubmit}>
      <Form.Row>
        <TextInput 
          label="Name" 
          name="name" 
          value={props.jobType.name} 
          onChange={props.onChange}
          error={props.errors.name} 
        />
      </Form.Row>

      <Form.Row>
        <ColorSelection
          label="Pick a Color" 
          name="color"
          value={props.jobType.color}
          onChange={props.onChange}
          style={{ paddingLeft: 5 }}
          error={props.errors.color}
        />
      </Form.Row>

      <Form.Row>
        <Checkbox 
          label="Needs Pickup"
          name="pickup"
          value={props.jobType.pickup}
          onChange={props.onChange}  
        />
      </Form.Row>  

      <Button variant="primary" type="submit" className="float-right">
        Submit
      </Button>
    </Form>
  )
}

export default JobTypeForm