import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import DatePicker from 'react-datepicker'
import Col from 'react-bootstrap/Col'

import TextInput from '../common/TextInput'
import { REPAIRS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

function RepairForm(props) {
  const [repairDate, setRepairDate] = useState(new Date())

  const [errors, setErrors] = useState({})
  const emptyRepair = {
    containerBoxId: props.containerBoxId,
    description: '',
    date: repairDate
  }
  const [repair, setRepair] = useState(emptyRepair)

  function handleChange({ target }) {
    setRepair({
      ...repair,
      [target.name]: target.value
    })
  }

  function validForm() {
    const _errors = {}

    if (!repair.description) _errors.description = 'Description is required'

    setErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  async function handleSubmit(evt) {
    evt.preventDefault()
    if (!validForm()) return

    const postRepair = async (l) => {
      try {
        await configuredAxios.post(REPAIRS_URL, l)
        setRepair(emptyRepair)
        props.handleShowForm(false)
        toast.success('Repair Created Successfully')
      } catch (error) {
        toast.error('Repair could not be created')
      }
    }
    postRepair(repair)
    props.handleRefresh(true)
  }

  return (
    <Form onSubmit={handleSubmit} id='repair-form'>
      <br />
      <Form.Group as={Col} controlId='formGridDate'>
        <Form.Row>
          <Form.Label>Repair Date</Form.Label>&nbsp;&nbsp;
          <DatePicker
            selected={repairDate}
            onChange={(date) => setRepairDate(date)}
          />
        </Form.Row>
      </Form.Group>
      <Form.Row>
        <TextInput
          label='Descripton'
          name='description'
          value={repair.description}
          onChange={handleChange}
          error={errors.description}
        />
      </Form.Row>

      <Button variant='primary' type='submit' className='float-right'>
        Submit
      </Button>
    </Form>
  )
}

export default RepairForm
