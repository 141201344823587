import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useHistory } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'

import TextInput from './common/TextInput'
import { DAILY_RUN_BY_CODE } from './api_urls'
import configuredAxios from '../configured-axios'

function TruckCode() {
  const history = useHistory()
  const [truckCode, setTruckCode] = useState(0)

  function handleChange(e) {
    setTruckCode(e.target.value)
  }

  function handleSubmit(e) {
    e.preventDefault()

    const complete = async () => {
      try {
        const response = await configuredAxios.get(DAILY_RUN_BY_CODE + truckCode)
        const id = response.data
        history.push('/truck-daily-run/' + id)
      } catch (error) {
        toast.error("Can't find truck with code " + truckCode)
      }
    }
    complete()
  }

  return (
    <div className='login-page'>
      <ToastContainer autoClose={3000} hideProgressBar />
      <div className='login-form'>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <TextInput
              label='Truck Run Number'
              name='truckNumber'
              value={truckCode}
              onChange={handleChange}
            />
          </Form.Row>
          <Form.Row>
            <Button
              variant='primary'
              className='btn-primary'
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Form.Row>
        </Form>
      </div>
    </div>
  )
}

export default TruckCode
