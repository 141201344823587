
/**
 * util.js
 * 
 * This file contains utility functions for components.
 */
import { MGE_CONTAINER_NUM } from '../globals'

/**
 * A mehod to filter options based on input.
 * @param {*} option The current option to compare against the input.
 * @param {*} input The user input to filter by.
 * @returns Returns the list of options that contains input
 */
export function filterOption(option, input) {
  return option.label.toString().includes(input)
}

/**
 * A function to compare containers
 * @param {*} a 
 * @param {*} b 
 * @returns 
 */
export function compareContainerNumbers(a,b) {
  if (a.id === MGE_CONTAINER_NUM || a.number < b.number) {
    return -1
  }
  else if (a.number > b.number) {
    return 1
  }
  else if (a.number === b.number) {
    return 0
  }
}