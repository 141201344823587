import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { RUNS_URL } from '../api_urls'

function DeleteRecurringModal(props) {
  const [deleteSeries, setDeleteSeries] = useState(false)

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>Delete Recurring Run</Modal.Header>
      {!deleteSeries ? (
        <Form>
          <Modal.Body>
            <p>Do you want to delete this occurence or the entire series?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={props.handleClose}>Cancel</Button>
            <Button variant='primary' onClick={() => props.handleDestroy(props.id)}>Delete Occurence</Button>
            <Button variant='primary' onClick={() => setDeleteSeries(true)}>Delete Series</Button>
          </Modal.Footer>
        </Form>
      ) : (
        <Form>
          <Modal.Body>
            <p>Do you want to delete the parent Daily Run if it is left empty?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => props.handleDestroySeries(props.recurrenceId, false)}>No</Button>
            <Button variant='primary' onClick={() => props.handleDestroySeries(props.recurrenceId, true)}>Yes</Button>
          </Modal.Footer>
        </Form>
      )}

    </Modal>
  )
}

export default DeleteRecurringModal
