import React from 'react'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import PropTypes from 'prop-types'

function TextInput(props) {
  let errorClass = ''

  if (props.error.length > 0) {
    errorClass += ' has-error'
  }

  return (
    <Form.Group
      as={Col}
      controlId={'formGrid' + props.label}
      className={errorClass}
    >
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        size={props.size}
        type={props.password ? 'password' : 'text'}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        defaultValue={props.defaultValue}
      />
      {props.error && <div className='alert alert-danger'>{props.error}</div>}
    </Form.Group>
  )
}

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

TextInput.defaultProps = {
  error: ''
}

export default TextInput
