import React from 'react'
import Table from 'react-bootstrap/Table'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getHighlightedText } from '../../textHelpers'

export default function ContainerBoxList({ containerBoxes, highlightedSearchTerm = '' }) {
  const history = useHistory()

  const containerBoxRows = []
  for (const box of containerBoxes) {
    // Prepare text for table display
    let containerNumber = box.container_number
    let containerSize = box.container_size
    let customerName = box.customer_name
    let locationText = ''
    if (box.location_address) {
      locationText = `${box.location_address}, ${box.location_city}, ${box.location_state} ${box.location_zip}`
    }
    let lastMoved = box.last_moved
    let containerDescription = box.container_description

    // Highlight text if searched
    if (highlightedSearchTerm) {
      containerNumber = getHighlightedText(containerNumber, highlightedSearchTerm)
      containerSize = getHighlightedText(containerSize, highlightedSearchTerm)
      customerName = getHighlightedText(customerName, highlightedSearchTerm)
      locationText = getHighlightedText(locationText, highlightedSearchTerm)
      lastMoved = getHighlightedText(lastMoved, highlightedSearchTerm)
      containerDescription = getHighlightedText(containerDescription, highlightedSearchTerm)
    }

    containerBoxRows.push(
      <tr onClick={() => history.push('/container_box/' + box.id)}>
        <td>{containerNumber}</td>
        <td>{containerSize}</td>
        <td><b>{customerName}</b></td>
        <td>
          {locationText}
        </td>
        <td style={{ whiteSpace: 'nowrap' }}>{lastMoved}</td>
        <td>{containerDescription}</td>
      </tr>
    )
  }

  if (containerBoxRows.length === 0) {
    containerBoxRows.push(
      <tr>
        <td colSpan={6}>
          No Containers Found
        </td>
      </tr>
    )
  }

  return (
    <Table striped bordered hover className='collection-table'>
      <thead>
        <tr>
          <th>Number</th>
          <th>Size</th>
          <th>Customer</th>
          <th>Location</th>
          <th>Last Moved</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {containerBoxRows}
      </tbody>
    </Table>
  )
}

ContainerBoxList.propTypes = {
  containerBoxes: PropTypes.array,
  highlightedSearchTerm: PropTypes.string
}
