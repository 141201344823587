import React from 'react'
import { Form, Alert } from 'react-bootstrap'
import ReactSelect from 'react-select'
import { shallowClone } from '../../helper'
import PropTypes from 'prop-types'

function DailyRunForm({ truckList, dailyRun, onChange = () => {}, errors = {} }) {
  function handleChangeDate(evt) {
    const newDailyRun = shallowClone(dailyRun)
    newDailyRun.date = evt.target.value
    onChange(newDailyRun)
  }

  function handleChangeTruck(value) {
    const newDailyRun = shallowClone(dailyRun)
    newDailyRun.truck = shallowClone(value)
    onChange(newDailyRun)
  }

  function truckListToSelectList(trucks) {
    return trucks.map((c) => { return { value: c.id, label: c.description } })
  }

  return (
    <Form>
      <Form.Row>
        <Form.Group className='mr-2'>
          <Form.Label>
            Date
          </Form.Label>
          <Form.Control onChange={handleChangeDate} value={dailyRun.date} type='date' />
          {errors.date && (
            <Alert variant='danger'>{errors.date}</Alert>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Truck
          </Form.Label>
          <ReactSelect
            className='basic-single'
            options={truckListToSelectList(truckList)}
            onChange={(value) => handleChangeTruck(value)}
            value={dailyRun.truck}
          />
          {errors.truck && (
            <Alert variant='danger'>{errors.truck}</Alert>
          )}
        </Form.Group>
      </Form.Row>
    </Form>
  )
}
DailyRunForm.propTypes = {
  truckList: PropTypes.array,
  dailyRun: PropTypes.object,
  onChange: PropTypes.func,
  errors: PropTypes.object
}

export default DailyRunForm
