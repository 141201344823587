import React from 'react'
import { Button, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'

const TablePaginator = ({
  variant = 'light',
  itemsPerPage = 20,
  itemsPerPageOptions = [10, 20, 50, 100],
  offset = 0,
  totalItems,
  onItemsPerPageSelect = () => {},
  onNextPage = () => {},
  onPreviousPage = () => {},
  onFirstPage = () => {},
  onLastPage = () => {}
}) => {
  const itemsPerPageDropdownItems = []
  for (const option of itemsPerPageOptions) {
    itemsPerPageDropdownItems.push(<Dropdown.Item key={option} eventKey={option}>{option}</Dropdown.Item>)
  }
  const start = offset + 1
  let end = offset + itemsPerPage
  if (end > totalItems) {
    end = totalItems
  }
  return (
    <ButtonGroup className='border rounded'>
      <Button disabled={start === 1} onClick={onFirstPage} variant={variant}>&lt;&lt;</Button>
      <Button disabled={start === 1} onClick={onPreviousPage} variant={variant}>&lt;</Button>
      <DropdownButton className='table-paginator-middle-button' onSelect={(value) => onItemsPerPageSelect(Number(value))} variant={variant} title={`${start} - ${end} of ${totalItems}`}>
        {itemsPerPageDropdownItems}
      </DropdownButton>
      <Button disabled={end === totalItems} onClick={onNextPage} variant={variant}>&gt;</Button>
      <Button disabled={end === totalItems} onClick={onLastPage} variant={variant}>&gt;&gt;</Button>
    </ButtonGroup>
  )
}
TablePaginator.propTypes = {
  variant: PropTypes.string,
  itemsPerPage: PropTypes.number,
  itemsPerPageOptions: PropTypes.array,
  offset: PropTypes.number,
  totalItems: PropTypes.number,
  onItemsPerPageSelect: PropTypes.func,
  onNextPage: PropTypes.func,
  onPreviousPage: PropTypes.func,
  onFirstPage: PropTypes.func,
  onLastPage: PropTypes.func
}

export default TablePaginator
