import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import configuredAxios from '../configured-axios'
import { EMAIL_ALERT_RECIPIENTS_URL } from './api_urls'
import { toast } from 'react-toastify'

const AdminPage = () => {
  const [emailAddresses, setEmailAddresses] = useState([])

  const [newEmailAddress, setNewEmailAddress] = useState('')

  const [showLoadingSpinner, setShowLoadingSpinner] = useState(true)

  const emailAddressRows = []
  for (const emailAddressObject of emailAddresses) {
    emailAddressRows.push(
      <Row key={emailAddressObject.id} className='mb-2 no-gutters'>
        <Container className='m-0'>
          <Row className='no-gutters align-items-md-center justify-content-md-between'>
            <Col md='auto'>{emailAddressObject.emailAddress}</Col>
            <Col md='auto'><Button variant='danger' onClick={() => { removeEmailAddress(emailAddressObject) }}>Delete</Button></Col>
          </Row>
        </Container>
      </Row>
    )
  }

  const addEmailAddress = (evt) => {
    evt.preventDefault()
    setShowLoadingSpinner(true)
    configuredAxios.post(EMAIL_ALERT_RECIPIENTS_URL, { emailAddress: newEmailAddress }).then((res) => {
      const newEmailAddresses = emailAddresses.slice()
      newEmailAddresses.push(res.data)
      setEmailAddresses(newEmailAddresses)
      setNewEmailAddress('')
      toast.success('Added new email recipient.')
      setShowLoadingSpinner(false)
    }).catch((err) => {
      toast.error('Could not add new email alert recipient.')
      setShowLoadingSpinner(false)
      console.log(err)
    })
  }

  const removeEmailAddress = (emailAddressRecipient) => {
    setShowLoadingSpinner(true)
    configuredAxios.delete(`${EMAIL_ALERT_RECIPIENTS_URL}/${emailAddressRecipient.id}`).then((res) => {
      const newEmailAddresses = emailAddresses.slice().filter((address) => {
        return address.id !== emailAddressRecipient.id
      })
      setEmailAddresses(newEmailAddresses)
      toast.success('Removed email recipient.')
      setShowLoadingSpinner(false)
    }).catch((err) => {
      toast.error('Could not remove email alert recipient.')
      setShowLoadingSpinner(false)
      console.log(err)
    })
  }

  useEffect(() => {
    setShowLoadingSpinner(true)
    configuredAxios.get(EMAIL_ALERT_RECIPIENTS_URL).then((res) => {
      setEmailAddresses(res.data)
      setShowLoadingSpinner(false)
    }).catch((err) => {
      toast.error('Could not retrieve email alert recipients')
      setEmailAddresses([])
      setShowLoadingSpinner(false)
      console.log(err)
    })
  }, [])

  let noRecipients = (<></>)
  if (emailAddressRows.length === 0 && !showLoadingSpinner) {
    noRecipients = (<p>No Email Alert Recipients Found</p>)
  }

  let loadingSpinner = (<></>)
  if (showLoadingSpinner) {
    loadingSpinner = (
      <Row className='justify-content-md-center'>
        <Col md='auto'>
          <Spinner animation='border'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </Col>
      </Row>
    )
  }

  return (
    <Container>
      <h2>Administrator Settings</h2>
      <Container>
        <Row>
          <Col>
            <h5>Container Email Alert Settings</h5>
            <p>Choose who to send daily alert emails to when containers have been unmoved for 30+ days.</p>
          </Col>
        </Row>
        <Row className='mb-2'>
          <Col>
            <Form onSubmit={(evt) => { addEmailAddress(evt) }}>
              <Form.Label>Add Email Address</Form.Label>
              <Form.Control className='mb-2' type='text' value={newEmailAddress} onChange={(evt) => { setNewEmailAddress(evt.target.value) }} />
              <Button type='submit' variant='dark'>Add Email Address</Button>
            </Form>
          </Col>
          <Col>
            <Container className='m-0 p-0'>
              <Row><Col>Email Addresses</Col></Row>
              {emailAddressRows}
              {loadingSpinner}
              <Row><Col className='mt-2'>{noRecipients}</Col></Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default AdminPage
