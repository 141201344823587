import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import { Plus } from "react-bootstrap-icons";

import { dmyString } from "../../helper";
import RepairForm from "./RepairForm";

function RepairTableRow(props) {
  return (
    <tr>
      <td>{dmyString(props.date)}</td>
      <td>{props.description}</td>
    </tr>
  );
}

function Rows(props) {
  if (props.repairs && props.repairs.length > 0) {
    return props.repairs.map((repair) => {
      return <RepairTableRow {...repair} key={repair.id} />;
    });
  } else {
    return (
      <tr>
        <td colSpan="2">No Repairs Found</td>
      </tr>
    );
  }
}

function RepairList(props) {
  const [showForm, setShowForm] = useState(false);
  return (
    <Container>
      <h4>
        Repairs&nbsp;
        <Plus onClick={() => setShowForm(!showForm)} />
      </h4>
      {showForm && (
        <RepairForm
          containerBoxId={props.containerBoxId}
          handleRefresh={props.handleRefresh}
          handleShowForm={setShowForm}
        />
      )}
      <Table bordered hover>
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <Rows repairs={props.repairs} />
        </tbody>
      </Table>
    </Container>
  );
}

export default RepairList;
