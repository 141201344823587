import React from 'react'

export function escapeRegExp(string) {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function getHighlightedText(text, highlight) {
  // Split on highlight term and include term into parts, ignore case
  const parts = String(text).split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'))
  return (
    <span> {parts.map((part, i) =>
      <span key={i} className={part.toLowerCase() === highlight.toLowerCase() ? 'highlighted-text' : ''}>
        {part}
      </span>)}
    </span>
  )
}
