import { useState } from 'react'

export default function useAccessTokens() {
  const getTokens = () => {
    const tokens = {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken')
    }
    return tokens
  }

  const [tokens, setTokens] = useState(getTokens())

  const saveTokens = (tokens) => {
    localStorage.setItem('accessToken', tokens.accessToken)
    localStorage.setItem('refreshToken', tokens.refreshToken)
    setTokens(tokens)
  }
  const hook = [
    tokens,
    saveTokens
  ]
  return hook
}
