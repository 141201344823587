import React, { useState, useEffect } from 'react'
import { Container, Row } from 'react-bootstrap'
import DatePicker from 'react-datepicker'
import configuredAxios from '../configured-axios'
import { DateTime } from 'luxon'
import { toast } from 'react-toastify'
import { ChevronRight, ChevronLeft } from 'react-bootstrap-icons'

import DailyRun from './daily_run/DailyRun'
import { GET_NEWS_FEED_URL } from './api_urls'

export default function NewsFeed({ authenticated }) {
  const [date, setDate] = useState(new Date())
  const [runs, setRuns] = useState([])

  useEffect(() => {
    const query = new URLSearchParams({
      date: DateTime.fromJSDate(date).startOf('day').toISODate()
    })

    configuredAxios.get(GET_NEWS_FEED_URL, { params: query }).then((res) => {
      setRuns(res.data)
    }).catch((err) => {
      toast.error('Could not retrieve daily runs')
      console.log(err)
    })
  }, [date])

  function backOneDay() {
    setDate(DateTime.fromJSDate(date).minus({ day: 1 }).toJSDate())
  }

  function forwardOneDay() {
    setDate(DateTime.fromJSDate(date).plus({ day: 1 }).toJSDate())
  }

  return (
    <Container className=''>
      <h2>News Feed</h2>
      <Container>
        <Row className='align-items-md-center'>
          <ChevronLeft onClick={backOneDay} />
          <DatePicker
            selected={date}
            onChange={setDate}
            className='news-feed-datepicker'
          />
          <ChevronRight onClick={forwardOneDay} />
        </Row>
        <div className='news-feed-grid'>
          {runs.map((run) => (
            <DailyRun {...run} key={run.id} authenticated={authenticated} />
          ))}
        </div>
      </Container>
    </Container>
  )
}
