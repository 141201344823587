import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import TextInput from '../common/TextInput'
import { Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'

function ContainerBoxForm({ containerBox, onChange, onSubmit, errors, loading }) {
  let loadingSpinner = (<></>)
  if (loading) {
    loadingSpinner = (<Spinner className='mr-2' size='sm' animation='border' />)
  }
  return (
    <Form onSubmit={onSubmit}>
      <br />
      <Form.Row>
        <TextInput
          label='Number'
          name='number'
          value={containerBox.number}
          onChange={onChange}
          error={errors.number}
        />
      </Form.Row>

      <Form.Row>
        <TextInput
          label='Size'
          name='size'
          value={containerBox.size}
          onChange={onChange}
          error={errors.size}
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          label='Description'
          name='description'
          value={containerBox.description}
          onChange={onChange}
        />
      </Form.Row>

      <Button variant='primary' type='submit' className='float-right' loading>
        {loadingSpinner}
        Submit
      </Button>
    </Form>
  )
}

ContainerBoxForm.propTypes = {
  containerBox: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  errors: PropTypes.object,
  loading: PropTypes.bool
}

export default ContainerBoxForm
