import React from 'react'
import { ButtonGroup, DropdownButton, Dropdown, Button, Container, Row, Col } from 'react-bootstrap'
import PropTypes from 'prop-types'

const TableSorter = ({ variant = 'light', sortOptions, sortBy, sortDirection = true, onToggleDirection = () => {}, onSortOptionChange = () => {} }) => {
  let sortDirectionDisplay = (<>&uarr;</>)
  if (!sortDirection) {
    sortDirectionDisplay = (<>&darr;</>)
  }
  const sortOptionDropdownItems = []
  for (const option of Object.keys(sortOptions)) {
    sortOptionDropdownItems.push(<Dropdown.Item key={option} eventKey={option}>{sortOptions[option]}</Dropdown.Item>)
  }
  return (
    <Container fluid className='p-0'>
      <Row className='align-items-md-center no-gutters flex-nowrap'>
        <Col className='mr-2' md='auto'><span className='float-right'>Sort:</span></Col>
        <Col>
          <ButtonGroup className='border rounded'>
            <DropdownButton className='table-sorter border-right' onSelect={(value) => { onSortOptionChange(value) }} variant={variant} title={sortOptions[sortBy]}>
              {sortOptionDropdownItems}
            </DropdownButton>
            <Button onClick={() => { onToggleDirection(sortDirection) }} variant={variant}>{sortDirectionDisplay}</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Container>
  )
}
TableSorter.propTypes = {
  variant: PropTypes.string,
  sortOptions: PropTypes.object,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.bool,
  onToggleDirection: PropTypes.func,
  onSortOptionChange: PropTypes.func
}

export default TableSorter
