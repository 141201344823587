import React, { useContext, useEffect } from 'react'
import Badge from 'react-bootstrap/Badge'
import { toast } from 'react-toastify'

import AlertContext from '../context/AlertContext'
import { UNREAD_ALERTS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

function UnreadAlerts() {
  const { unreadAlerts, setUnreadAlerts } = useContext(AlertContext)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(UNREAD_ALERTS_URL)
        setUnreadAlerts(response.data)
      } catch (error) {
        toast.error('Could not retrieve Alerts')
      }
    }
    fetchData()
  }, [setUnreadAlerts])

  if (unreadAlerts > 0) {
    return (
      <Badge pill variant='danger'>
        {unreadAlerts}
      </Badge>
    )
  } else {
    return ''
  }
}

export default UnreadAlerts
