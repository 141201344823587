import React, { useState } from 'react'
import { Card, CardDeck, Image, Modal, Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Trash } from 'react-bootstrap-icons'

const ViewPicturesModal = ({ show, onHide, runId, runPictureFilenames, onPictureDelete, authenticated = false }) => {
  const pictureElements = []
  const staticPicturesDirectoryUri = `/api-static/uploads/${runId}`
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [fileToDelete, setFileToDelete] = useState(undefined)

  function handlePictureDelete(filename) {
    setShowDeleteConfirmationModal(false)
    onHide()
    onPictureDelete(filename)
  }

  for (const filename of runPictureFilenames) {
    const imageUri = `${staticPicturesDirectoryUri}/${filename}`
    pictureElements.push(
      <Card key={filename} className='mb-2' style={{ minWidth: '250px', maxWidth: '600px' }}>
        <Card.Body>
          <p className='float-left'>{filename}</p>
          {
            authenticated &&
              <Button variant='danger' className='float-right mb-2' onClick={() => {
                setFileToDelete(filename)
                setShowDeleteConfirmationModal(true)
              }}><Trash /></Button>
          }          
          <Image fluid src={imageUri} alt='' />
        </Card.Body>
      </Card>
    )
  }
  let pictureDisplay = (<p>No pictures have been added to this run.</p>)
  if (pictureElements.length > 0) {
    pictureDisplay = (
      <CardDeck>
        {pictureElements}
      </CardDeck>
    )
  }
  return (
    <Modal size='xl' show={show} onHide={onHide}>
      <Modal.Header>Run Pictures</Modal.Header>
      <Modal.Body>
        {pictureDisplay}
        <Button variant='secondary' onClick={() => { onHide() }} className='float-right'>Close</Button>
      </Modal.Body>
      <Modal backdrop={false} show={showDeleteConfirmationModal} onHide={() => { setShowDeleteConfirmationModal(false) }} centered>
        <Modal.Body>
          <p>Are you sure you want to delete this picture?</p>
          <p>This cannot be un-done.</p>
          <Button variant='danger' onClick={() => { handlePictureDelete(fileToDelete) }} className='float-right'>Delete</Button>
          <Button variant='secondary' onClick={() => { setShowDeleteConfirmationModal(false) }} className='float-right mr-2'>Cancel</Button>
        </Modal.Body>
      </Modal>
    </Modal>
  )
}

ViewPicturesModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  runId: PropTypes.number,
  runPictureFilenames: PropTypes.array,
  onPictureDelete: PropTypes.func,
  authenticated: PropTypes.bool
}

export default ViewPicturesModal
