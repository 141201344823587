import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import LocationForm from './LocationForm'
import PropTypes from 'prop-types'

function LocationUpdateModal({ location, locationErrors, onChange, show, onHide, onSubmit }) {
  return (
    <Modal show={show} onExited={onHide}>
      <Modal.Header>Edit Address</Modal.Header>
      <Modal.Body>
        <LocationForm
          location={location}
          locationErrors={locationErrors}
          onChange={onChange}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='seconday' onClick={onHide}>
          Cancel
        </Button>
        <Button onClick={onSubmit} variant='primary'>Save Changes</Button>
      </Modal.Footer>
    </Modal>
  )
}

LocationUpdateModal.propTypes = {
  location: PropTypes.object,
  locationErrors: PropTypes.object,
  onChange: PropTypes.func,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func
}

export default LocationUpdateModal
