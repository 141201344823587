import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import TextInput from "../common/TextInput";
import Checkbox from "../common/Checkbox";

function TruckForm(props) {
  return (
    <Form onSubmit={props.onSubmit}>
      <Form.Row>
        <TextInput
          label="Number"
          name="number"
          value={props.truck.number}
          onChange={props.onChange}
          error={props.errors.number}
        />
      </Form.Row>

      <Form.Row>
        <TextInput
          label="Description"
          name="description"
          value={props.truck.description}
          onChange={props.onChange}
        />
      </Form.Row>

      <Form.Row>
        <Checkbox
          label="Active"
          name="active"
          value={props.truck.active}
          onChange={props.onChange}
        />
      </Form.Row>

      <Button variant="primary" type="submit" className="float-right">
        Submit
      </Button>
    </Form>
  );
}

export default TruckForm;
