import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import TextInput from '../common/TextInput'
import Checkbox from '../common/Checkbox'
import RunTableList from '../runs/RunTableList'
import { TRUCKS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

function truckUrlWithId(id) {
  return TRUCKS_URL + '/' + id
}

function Truck() {
  const emptyTruck = {
    number: '',
    description: '',
    active: true,
    daily_runs: []
  }

  const { id } = useParams()
  const [truck, setTruck] = useState(emptyTruck)
  const [errors, setErrors] = useState({})
  const [refresh, setRefresh] = useState(false)

  function validForm() {
    const _errors = {}

    if (!truck.number) _errors.number = 'Number is required'
    if (!truck.description) _errors.description = 'Description is required!'

    setErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  function handleChange({ target }) {
    let val = ''
    if (target.name === 'active') {
      val = !!target.checked
    } else {
      val = target.value
    }
    setTruck({
      ...truck,
      [target.name]: val
    })
  }

  async function handleSubmit(evt) {
    evt.preventDefault()
    if (!validForm()) return

    const putTruck = async (c) => {
      try {
        const t = {
          active: c.active,
          createdAt: c.createdAt,
          description: c.description,
          id: c.id,
          number: c.number,
          updatedAt: c.updatedAt
        }
        await configuredAxios.put(truckUrlWithId(id), t)
        toast.success('Truck Edited Successfully')
        setRefresh(true)
        setTruck(emptyTruck)
      } catch (error) {
        toast.error('Truck could not be edited')
      }
    }
    putTruck(truck)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(truckUrlWithId(id))
        setTruck(response.data)
      } catch (error) {
        toast.error('Could not retrieve Truck with id: ' + id)
      }
    }
    fetchData()
    setRefresh(false)
  }, [id, refresh])

  return (
    <Container>
      <h2>Edit Truck</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <TextInput
            label='Number'
            name='number'
            value={truck.number}
            onChange={handleChange}
            error={errors.number}
          />
          <TextInput
            label='Description'
            name='description'
            value={truck.description}
            onChange={handleChange}
            error={errors.description}
          />
        </Form.Row>
        <Form.Row>
          <Checkbox
            label='Active'
            name='active'
            value={truck.active}
            onChange={handleChange}
          />
        </Form.Row>
        <Button variant='primary' type='submit' className='float-right'>
          Submit
        </Button>
      </Form>
      <RunTableList daily_runs={truck.daily_runs} />
    </Container>
  )
}

export default Truck
