import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import TextInput from '../common/TextInput'
import { CONTAINERS_URL } from '../api_urls'
import configuredAxios from '../../configured-axios'

function ContainerEditModal(props) {
  const initialContainer = {
    number: props.containerBox.number,
    size: props.containerBox.size,
    description: props.containerBox.description
  }

  const [containerBox, setContainerBox] = useState(initialContainer)
  const [errors, setErrors] = useState({})

  function handleChange({ target }) {
    setContainerBox({
      ...containerBox,
      [target.name]: target.value
    })
  }

  function validForm() {
    const _errors = {}

    if (!containerBox.number) _errors.number = 'Number is required'
    if (!containerBox.size) _errors.size = 'Size is required!'

    setErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  async function handleSubmit(evt) {
    evt.preventDefault()
    if (!validForm()) return

    const postContainerBox = async (l) => {
      Object.assign(l, { id: props.containerId })
      try {
        await configuredAxios.put(`${CONTAINERS_URL}/${props.containerId}`, l)
        toast.success('Container Updated Successfully')
      } catch (error) {
        toast.error('Container could not be updated')
      }
    }
    postContainerBox(containerBox)
    props.handleClose()
    props.handleRefresh(true)
  }

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>Edit Container</Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <Form.Row>
            <TextInput
              label='Number'
              name='number'
              value={containerBox.number}
              onChange={handleChange}
              error={errors.number}
            />
          </Form.Row>

          <Form.Row>
            <TextInput
              label='Size'
              name='size'
              value={containerBox.size}
              onChange={handleChange}
              error={errors.size}
            />
          </Form.Row>
          <Form.Row>
            <TextInput
              label='Description'
              name='description'
              value={containerBox.description}
              onChange={handleChange}
            />
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='seconday' onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant='primary' type='submit'>Save Changes</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ContainerEditModal
