import React, { useEffect, useState } from 'react'
import { Container, Spinner, Row, Col, Table } from 'react-bootstrap'
import { ChevronLeft } from 'react-bootstrap-icons'
import { useParams, useHistory } from 'react-router-dom'
import configuredAxios from '../configured-axios'
import { CONTAINER_REPORT_URL, LOCATIONS_URL } from './api_urls'
import { toast } from 'react-toastify'

const LocationDetailPage = () => {
  const { locationId } = useParams()
  const [location, setLocation] = useState(null)
  const [containers, setContainers] = useState([])
  const history = useHistory()

  useEffect(() => {
    // Fetch location information
    configuredAxios.get(`${LOCATIONS_URL}/${locationId}`).then((res) => {
      setLocation(res.data)
    }).catch((err) => {
      console.log(err)
      toast.error('Could not load location details.')
    })

    // Fetch container location list
    configuredAxios.get(CONTAINER_REPORT_URL, {
      params: new URLSearchParams({
        locationId: locationId
      })
    }).then((res) => {
      setContainers(res.data)
    }).catch((err) => {
      console.log(err)
      toast.error('Could not load location container list.')
    })
  }, [locationId])

  const containerRows = []
  for (const container of containers) {
    containerRows.push(
      <tr
        style={{
          cursor: 'pointer'
        }}
        onClick={() => { history.push(`/container_box/${container.container_box_id}`) }}
      >
        <td>{container.container_box_number}</td>
        <td>{container.container_box_description}</td>
        <td>{new Date(container.last_moved).toLocaleDateString()}</td>
      </tr>
    )
  }

  if (!location) {
    // Show loading spinner if location hasn't been loaded yet
    return (
      <Container>
        <h2>Loading...</h2>
        <Container>
          <Spinner animation='border' />
        </Container>
      </Container>
    )
  } else {
    let headerText = location.address
    if (location.nickname) {
      headerText = `${location.nickname}: ${location.address}`
    }

    let noContainersRow = (<></>)
    if (containers.length === 0) {
      noContainersRow = (<tr><td colSpan={4}>No containers found at this location.</td></tr>)
    }

    return (
      <Container>
        <h2>{headerText}</h2>
        <Container>
          <div
            style={{
              display: 'flex',
              placeItems: 'center',
              color: '#CC0000',
              cursor: 'pointer',
              userSelect: 'none',
              width: 'fit-content',
              whiteSpace: 'nowrap'
            }}
            className='mb-2'
            onClick={() => { history.goBack() }}
          ><ChevronLeft />Back to Customer
          </div>
          <Row>
            <Col>
              <h5>Address</h5>
              <div>
                <b>Address Line 1: </b>
                {location.address}
              </div>
              <div>
                <b>Address Line 2: </b>
                {location.address2}
              </div>
              <div>
                <b>City: </b>
                {location.city}
              </div>
              <div>
                <b>State: </b>
                {location.state}
              </div>
              <div>
                <b>Zip Code: </b>
                {location.zip}
              </div>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <h5>Containers at this Location</h5>
              <Table hover>
                <thead>
                  <th>Box Number</th>
                  <th>Description</th>
                  <th>Last Moved</th>
                </thead>
                <tbody>
                  {containerRows}
                  {noContainersRow}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </Container>
    )
  }
}

export default LocationDetailPage
