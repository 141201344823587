import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import { Check } from 'react-bootstrap-icons'
import { toast } from 'react-toastify'

import { JOB_TYPES_URL } from './api_urls'
import JobTypeForm from './job_types/JobTypeForm'
import configuredAxios from '../configured-axios'

export default function JobType() {
  const emptyJobType = {
    name: '',
    color: '',
    pickup: true
  }

  const [jobType, setJobType] = useState(emptyJobType)
  const [jobs, setJobs] = useState([])
  const [refresh, setRefresh] = useState(true)
  const [errors, setErrors] = useState({})

  function validForm() {
    const _errors = {}

    if (!jobType.name) _errors.name = 'Name is required'
    if (!jobType.color) _errors.color = 'Color is required!'

    setErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  function handleChange({ target }) {
    let value = null
    if (target.name === 'pickup') {
      value = target.checked
    } else {
      value = target.value
    }
    setJobType({
      ...jobType,
      [target.name]: value
    })
  }

  async function handleSubmit(evt) {
    evt.preventDefault()
    if (!validForm()) return

    const postJobType = async (jt) => {
      try {
        await configuredAxios.post(JOB_TYPES_URL, jt)
        setJobType(emptyJobType)
        setRefresh(true)
        toast.success('Job Type Created Successfully')
      } catch (error) {
        toast.error('Job Type could not be created')
      }
    }
    postJobType(jobType)
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await configuredAxios.get(JOB_TYPES_URL)
        setJobs(response.data)
      } catch (error) {
        toast.error('Could not retrieve Job Types')
        setJobs([])
      }
    }
    fetchData()
    setRefresh(false)
  }, [refresh])

  return (
    <Container>
      <h2>Job Types</h2>
      <Container>
        <JobTypeForm
          jobType={jobType}
          onSubmit={handleSubmit}
          onChange={handleChange}
          errors={errors}
        />
        <Table borderless striped>
          <thead>
            <tr>
              <th />
              <th>Need Pickup?</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((value, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Button
                      style={{ backgroundColor: value.color }}
                      className='job-type-button'
                    >
                      {value.name}
                    </Button>
                  </td>
                  <td>{value.pickup && <Check />}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Container>
    </Container>
  )
}
