import React, { useState } from 'react'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import { GithubPicker } from 'react-color'

function ColorSelection(props) {
  const colorId = "formGridColor"
  const [display, setDisplay] = useState(false);
  
  function handleClick(event) {
    setDisplay(!display)
  }

  function handleChange(color, event) {
    const element = document.getElementById(colorId)
    element.style.backgroundColor= color.hex
    setReactInputValue(element, color.hex);
    setDisplay(false)
  }

  //
  // Programaticaly changing text inputs in React
  // Stolen from https://github.com/facebook/react/issues/10135
  //
  function setReactInputValue(input, value) {
    const previousValue = input.value;
  
    input.value = value;
  
    const tracker = input._valueTracker;
    if (tracker) {
      tracker.setValue(previousValue);
    }
  
    input.dispatchEvent(new Event('change', { bubbles: true }));
  }

return(
    <Form.Group controlId={colorId} style={props.style}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Control 
        type="text" 
        value={props.value} 
        name={props.name}
        readOnly="readonly"
        onClick={handleClick}
        onChange={props.onChange}
      />
      { display && <GithubPicker triangle='hide' onChange={handleChange} color={ props.value }/> }
      { props.error && <div className="alert alert-danger">{props.error}</div>}
    </Form.Group>
  )
}

ColorSelection.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string
}

export default ColorSelection