import React, { useEffect, useState, useCallback } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'

import AuthenticatedWrapper from './components/AuthenticatedWrapper'
import CustomerPage from './components/CustomerPage'
import Customer from './components/customers/Customer'
import Truck from './components/trucks/Truck'
import NewsFeed from './components/NewsFeed'
import JobTypePage from './components/JobTypePage'
import ContainerBoxPage from './components/ContainerBoxPage'
import RunPage from './components/RunPage'
import TruckPage from './components/TruckPage'
import LoginPage from './components/LoginPage'
import TruckCode from './components/TruckCode'
import AlertPage from './components/AlertPage'
import DailyRunDetail from './components/daily_run/DailyRunDetail'
import AlertContext from './components/context/AlertContext'
import ContainerBox from './components/container_box/ContainerBox'
import TruckDailyRunDetail from './components/daily_run/TruckDailyRunDetail'

import useAccessTokens from './useTokens'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.min.css'
import AdminPage from './components/AdminPage'
import TruckingListPage from './components/TruckingListPage'
import LocationDetailPage from './components/LocationDetailPage'

export default function App() {
  const [unreadAlerts, setUnreadAlerts] = useState(0)

  // eslint-disable-next-line no-unused-vars
  const [_tokens, setTokens] = useAccessTokens()

  const history = useHistory()

  const onSuccessfulLogin = () => {
    history.push('/')
  }

  return (
    <AlertContext.Provider value={{ unreadAlerts, setUnreadAlerts }}>
      <Switch>
        <Route path='/login'>
          <LoginPage setTokens={setTokens} onSuccessfulLogin={onSuccessfulLogin} />
        </Route>
        <Route path='/truck-code'>
          <TruckCode />
        </Route>
        <Route path='/truck-daily-run/:id'>
          <TruckDailyRunDetail />
        </Route>
        <Route path='/' exact>
          <AuthenticatedWrapper>
            <NewsFeed authenticated />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/admin'>
          <AuthenticatedWrapper>
            <AdminPage />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/alerts'>
          <AuthenticatedWrapper>
            <AlertPage />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/customer/:id'>
          <AuthenticatedWrapper>
            <Customer />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/customer'>
          <AuthenticatedWrapper>
            <CustomerPage />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/location/:locationId'>
          <AuthenticatedWrapper>
            <LocationDetailPage />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/trucking-list'>
          <AuthenticatedWrapper>
            <TruckingListPage />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/run/:id'>
          <AuthenticatedWrapper>
            <DailyRunDetail authenticated />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/run'>
          <AuthenticatedWrapper>
            <RunPage />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/job_types'>
          <AuthenticatedWrapper>
            <JobTypePage />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/truck/:id'>
          <AuthenticatedWrapper>
            <Truck />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/truck'>
          <AuthenticatedWrapper>
            <TruckPage />
          </AuthenticatedWrapper>
        </Route>
        <Route exact path='/container_box'>
          <AuthenticatedWrapper>
            <ContainerBoxPage />
          </AuthenticatedWrapper>
        </Route>
        <Route path='/container_box/:id'>
          <AuthenticatedWrapper>
            <ContainerBox authenticated />
          </AuthenticatedWrapper>
        </Route>
      </Switch>
    </AlertContext.Provider>
  )
}
